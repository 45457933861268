#socialAnalyticsChart{
  flex: 1;
  height: 476px;
}
#socialAnalyticsChart .socialAnalyticsChartContainer{
  width: 100%;
  height: calc(100% - 60px);
}
#socialAnalyticsChart .socialAnalyticsChartLoading,
#socialAnalyticsChart .socialAnalyticsChartGraph{
  width: 100%;
  height: 100%;
}
#socialAnalyticsChart [data-show="true"]{
  display: block;
}
#socialAnalyticsChart [data-show="false"]{
  display: none;
}
.socialAnalyticsSwitch{
  display: flex;
}
.socialAnalyticsSwitch label{
  display: block;
  margin-bottom: 0px;
  padding: 4px;
  margin: 0px 4px;
  cursor: pointer;
}
.socialAnalyticsSwitch label input[type=radio]{
  display: none;
}
.socialAnalyticsSwitch label input[type=radio]:checked + p{
  color: #ccff33;
}