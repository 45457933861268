#socialAudience{
  height: 476px;
  width: 100%;
}
.socialAudienceContainer{
	height: calc(100% - 60px);
	width: 100%;
}
.socialAudienceContainer > div{
	width: 100%;
	height: 100%;
}
#socialAges{
  height: calc(50% - 5px);
}
#socialGender{
  height: calc(50% - 5px);
}
.socialAudiencegender{
	width: 100%;
	height: 130px;
	display: flex;
	flex-direction: row;
}
.socialAudiencegenderFemale,
.socialAudiencegenderMale{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: .5px solid #fff;
}
.socialAudiencegenderFemale i,
.socialAudiencegenderMale i{
	font-size: 60px;
}
.socialAudiencegenderFemale p,
.socialAudiencegenderMale p{
	margin-bottom: 0px;
	margin-top: 10px;
}
.socialAudiencegenderFemale{
	background-color: #ff8bd8;
}
.socialAudiencegenderMale{
	background-color: #4fabe6;
}