.socialSidebar{
  width: 70px;
  position: fixed;
  top: 80px;
  left: 0px;
  background: #CF3;
  height: 100%;
  min-height: 600px;
  box-shadow: 10px 0px 22px -8px rgba(0, 0, 0, 0.66);
  z-index: 10;  
}
.socialSidebar div{
  border-bottom-color: #36383A;
  border-bottom-style: dashed;
  padding-bottom: 10px;
}
.socialSidebar div:last-child{
  padding-top: 10px;
  border-bottom: none;
}
.socialSidebar div a{
  text-decoration: none;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #36383A;
  cursor: pointer;
  margin-bottom: 0px;
  position: relative;
}
.socialSidebarLabel{
  position: absolute;
  height: 100%;
  left: 0px;
  z-index: -1;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  background-color: #CF3;
  color: #36383A;
  font-size: 16px;
  opacity: 0;
  transition: all .5s;
  white-space: nowrap;
}
.socialSidebar div a i{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #CF3;
}
.socialSidebar div a.active,
.socialSidebar div a:hover{
  background-color: #36383A;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
}
.socialSidebar div a:hover i,
.socialSidebar div a.active i{
  background-color: #36383A;
  color: #fff;
}
.socialSidebar div a:hover .socialSidebarLabel{
  left: 70px;
  opacity: 1;
}
.socialSidebar div.dashboardSidebarProviders a.active .dashboardSidebarProviderImage,
.socialSidebar div.dashboardSidebarProviders a:hover .dashboardSidebarProviderImage{
  border: 1px solid #CF3;
}
.socialSidebar div.dashboardSidebarProviders a{
  height: 40px;
}
.socialSidebar .dashboardSidebarProviderImage{
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #36383A;
  border-radius: 50%;
  background-color: #fff;
  border-bottom-style: solid !important;
}
.socialSidebar div a:hover .dashboardSidebarLabel{
  left: 70px;
  opacity: 1;
}