#socialSentiment{
	width: 100%;
	height: calc((476px / 2) - 5px);
}
#socialSentiment .all{
	margin: 0px;
	cursor: pointer;
}
.socialSentimentContainer{
	width: 100%;
	height: calc(100% - 60px);	
}
.socialSentimentContainer > div{
	width: 100%;
	height: 100%;
}