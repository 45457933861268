.LoadingMind{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.LoadingMind svg{
	width: 100px;
}
.LoadingMindPath {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s ease-in-out	 infinite, opacity 5s ease-in-out infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes opacity {
  from {
    marginTop: 0;
    opacity: 1;
  }
  to {
    marginTop: 15;
    opacity: 0;
  }
}