body {
  width: 100%;
  height: 100%;
  background: #2A2A2A;
  margin: 0;
  min-height: 300px;
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
}

body.show-sidebar {
  overflow: hidden;
}

#MainContainer{
  width: 100%;
}
.anychart-credits {
  display: none;
}

.App>.login {
  padding-top: 0;
}

.home-spinner {
  text-align: center;
  height: calc(100vh - 80px);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.spinner-container {
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: calc(100vh - 165px);
}

.bp3-spinner-head {
  stroke: #CF3 !important;
  opacity: .4;
}

.hidden {
  display: none;
}

.no-pad-t-b {
  padding-top: 0;
  padding-bottom: 0;
}

.no-pad-l-f {
  padding-left: 0;
  padding-right: 0;
}

.no-pad-t {
  padding-top: 0;
}

.no-pad-b {
  padding-bottom: 0;
}

.no-pad-l {
  left: 0;
}

.no-pad {
  padding: 0;
}

.button-primary,
.bp3-intent-primary {
  background-color: #CF3 !important;
  color: #2A2A2A !important;
}
.bp3-button.bp3-disabled,
.bp3-disabled,
.bp3-button:disabled
.bp3-disabled:disabled{
  background-color: #646464 !important;
  color: #fff !important;
  cursor: not-allowed !important;
}

.button-primary,
.bp3-intent-primary {
  background-color: #CF3 !important;
  color: #2A2A2A !important;
}

.bp3-card {
  background: #363737 !important;
}

.bp3-dialog.bp3-dark,
.bp3-dark .bp3-dialog {
  background-color: #2A2A2A;
  border: 1px solid #CEFF33;
  padding-bottom: 0px;
  padding: 10px 14px;
}

.bp3-table-container {
  margin-top: 1rem !important;
}

.bp3-dark .bp3-button.btn-outline {
  background-color: initial;
  border: 1px solid #545454;
  margin-left: 5px;
}

.bp3-dark .bp3-dialog-header {
  background-color: #2a2a2a;
  box-shadow: none;
}

.bp3-dark .bp3-datepicker {
  background-color: #363737;
}

.bp3-dark .bp3-menu {
  background-color: #363737;
}

.bp3-dark .bp3-table-quadrant,
.bp3-dark .bp3-table-column-headers,
.bp3-dark .bp3-table-row-headers,
.bp3-dark .bp3-table-menu,
.bp3-dark .bp3-table-cell-client {
  background-color: #363737;
}

.bp3-dark .bp3-table-truncated-text {
  max-height: initial;
}

.bp3-dark .bp3-table-cell-col-1 {
  text-align: right;
}

.bp3-dark .bp3-table-cell-col-1 .bp3-table-column-name {
  text-align: right;
}

.bp3-dark .bp3-table-column-headers .bp3-table-header {
  padding: .5rem;
}

.bp3-dark .bp3-table-column-headers .bp3-table-header .bp3-table-column-name-text {
  padding: 0;
  color: #CF3;
  font-size: 1rem;
  font-weight: bold;
}

.bp3-popover.bp3-dark,
.bp3-dark .bp3-popover .bp3-popover-arrow-fill {
  fill: #363737 !important;
}

.bp3-daterangepicker .bp3-daterangepicker-shortcuts+.DayPicker {
  border-left: 0;
}

.bp3-popover.bp3-dark .bp3-popover-arrow-fill,
.bp3-dark .bp3-popover .bp3-popover-arrow-fill {
  fill: #363737 !important;
}

.bp3-datepicker .bp3-menu .bp3-menu-item {
  border: 1px solid #545454 !important;
  border-radius: 5px;
  color: #fff;
  white-space: nowrap;
  margin-bottom: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.bp3-datepicker .bp3-menu .bp3-menu-item>.bp3-fill {
  font-size: 13px;
}

.bp3-datepicker .bp3-datepicker-caption {
  border-bottom: 0;
}

.bp3-datepicker .DayPicker-Day {
  padding: 0 9px;
  font-size: 14px;
}

.bp3-datepicker .DayPicker-Day--selected-range,
.bp3-datepicker .DayPicker-Day--hovered-range {
  background-color: rgba(204, 255, 51, 0.1) !important;
}

.bp3-datepicker .DayPicker-Day--selected-range-start,
.bp3-datepicker .DayPicker-Day--hovered-range-start,
.bp3-datepicker .DayPicker-Day--selected-range-end,
.bp3-datepicker .DayPicker-Day--hovered-range-end {
  background-color: #CF3 !important;
  color: #2A2A2A !important;
}

.bp3-datepicker .DayPicker-Day--disabled {
  text-decoration: line-through;
}

.subtitle {
  text-align: center;
  border-bottom: 2px solid #CF3;
  height: 40px;
  color: #636363;
  font-weight: bold;
  margin-bottom: 1.3rem;
  font-weight: 900;
  font-size: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle__no-border {
  border-bottom: none;
  line-height: 1;
}

.text-center {
  text-align: center;
}

.ReactVirtualized__Table {
  margin-top: 1rem;
}

.ReactVirtualized__Table__headerColumn {
  color: #CF3;
  font-size: 15px;
}

.ReactVirtualized__Table__rowColumn {
  border: 1px solid #4a4a4a;
  margin: 0;
  padding: 5px 0;
  font-size: 11px;
  padding-left: 10px;
}

.input-modal .bp3-label,
.input-modal .bp3-alert-body {
  text-align: center;
}

.question-tooltip {
  cursor: pointer;
  background-color: #2a2a2a;
  border-radius: 50%;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 1;
  top: 15px;
  right: 0;
}

.question-tooltip i {
  font-size: 12px;
}

.question-tooltip__left {
  right: auto;
  left: 0;
}

.bp3-tooltip .bp3-popover-content {
  background-color: #2a2a2a;
}

.bp3-tooltip .bp3-popover-arrow-fill {
  fill: #2a2a2a;
}

.ReactVirtualized__Table__Grid::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ReactVirtualized__Table__Grid::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 4px solid #363737;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.ReactVirtualized__Table__Grid::-webkit-scrollbar-track {
  background-color: #363737;
  border-radius: 8px;
}

.ReactVirtualized__Table__headerColumn:nth-child(2),
.ReactVirtualized__Table__headerColumn:nth-child(3) {
  text-align: right;
}

.ReactVirtualized__Table__rowColumn:nth-child(2),
.ReactVirtualized__Table__rowColumn:nth-child(3) {
  padding-right: 10px;
  text-align: right;
}


/*TODO Component Table*/
table{
  display: flex;
  flex-direction: column;
}
td{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
div[id*="-license-text"] {
  display: none;
}

*,
:focus{
  outline: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
}
.top-container{
  position: fixed;
  width: 100%;
  padding-top: 20px;
  padding-left: 80px;
  padding-right: 10px;
  left: 0px;
  background: #2A2A2A;
  z-index: 9;
}
.social-intelligence__container{
  padding-top: 70px;
}
.searchContainer{
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchContainer .searchSmart{
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchContainer .searchSmart input[type=text]{
  height: 28px;
  padding: 0px 10px;
}
.searchContainer .searchSmart .searchFindIcon{
  background-color: #CEFF33;
  display: inline-block;
  height: 28px;
  width: 28px;
}
.searchContainer .searchSmart .searchFindIcon i{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #636363;
  width: 100%;
  height: 100%;
}
.searchContainer .searchCalendarIcon{
  background-color: #CEFF33;
  display: inline-block;
  height: 28px;
  width: 28px;
  position: relative;
  top: 2px;
}
.searchContainer .searchCalendarIcon i{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #636363;
  width: 100%;
  height: 100%;
}
.searchContainer input[type=text]{
  background-color: #363737;
  border: none;
  color: #636363;
  height: 28px;
}
.tippy-tooltip-content{
  font-size: 14px;
}
::placeholder {
  color: #636363;
}
[class*=col-], [class*=col_], [class~=col]{
  padding: 0px 4px 8px;
}
.bp3-portal{
  z-index: 100;
}
.smart-mark{
  color: #CCFF33; 
  font-weight: bold;
}