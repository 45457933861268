#socialTotalActivity{
  height: 476px;
  width: 100%;
}
.socialTotalActivityContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.socialTotalActivityContainer > div{
  width: 100%;
  height: 100%;
}
#socialTotalActivity .totalActivityItems{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
#socialTotalActivity .totalActivityItem{
  width: calc(50% - 20px);
  height: calc(50% - 20px);
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#socialTotalActivity .totalActivityIcon{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 26px;
}
#socialTotalActivity .totalActivityValue{
  font-size: 36px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px;
}
#socialTotalActivity .totalActivityName{
  color: #636363;
  font-weight: 700;
  font-size: 14px;
  margin: 5px;
  text-align: center;
  text-transform: capitalize;
}
#socialTotalActivity .totalActivityimpressions{
  background-color: #cf3;
  color: #53585f
}
#socialTotalActivity .totalActivityengagements{
  background-color: #69ade1;
}
#socialTotalActivity .totalActivityposts{
  background-color: #f79a3a;
}
#socialTotalActivity .totalActivityvideo_views{
  background-color: #ea5f61;
}