#trendingTraffic{
  height: 476px;
}
.trendingTrafficContainer{
  height: calc(100% - 60px);
}
.trendingTrafficContainer > div{
  width: 100%;
  height: 100%;
}
#trendingTrafficChart{
	height: 100%;
}
#trendingTraffic table{
	width: 100%;
}
#trendingTraffic table,
#trendingTraffic td,
#trendingTraffic th{  
  border: .5px solid #4a4a4a;
}
#trendingTraffic thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#trendingTraffic tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#trendingTraffic tr{
  display: flex;
  width: 100%;
}
#trendingTraffic thead tr th:first-child,
#trendingTraffic tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#trendingTraffic thead tr th:last-child,
#trendingTraffic tbody tr td:last-child{
  text-align: right;
  width: 96px;
}
#trendingTraffic th,
#trendingTraffic td{
  padding: 4px;
}