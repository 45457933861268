#googleLocation{
  height: 476px;
}
.googleLocationContainer{
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
.googleLocationSessionsTitle{
  color: #fff;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  margin: 0px;
}
.googleLocationSessionsValue{
  font-weight: 700;
  color: #cf3;
  margin: 15px;
  font-size: 25px;
  text-align: center;
  margin: 0px;
}
.googleLocationFlags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 152px;
  max-width: 350px;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}
.googleLocationFlag{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 78px;
  height: 52px;
  margin: 4px;
  cursor: pointer;
}
#googleLocation table{
  width: 100%;
}
#googleLocation table,
#googleLocation td,
#googleLocation th{  
  border: .5px solid #4a4a4a;
}
#googleLocation thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googleLocation tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#googleLocation tr{
  display: flex;
  width: 100%;
}
#googleLocation thead tr th:first-child,
#googleLocation tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#googleLocation thead tr th:last-child,
#googleLocation tbody tr td:last-child{
  text-align: right;
  flex-basis: 90px;
}
#googleLocation th,
#googleLocation td{
  padding: 4px;
}