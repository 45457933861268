#trendingPageViews{
	height: calc((476px / 2) - 5px);
  width: 100%;
}
.trendingPageViewsContainer{
	height: calc(100% - 68px);
  width: 100%;
}
.trendingPageViewsContainer > div{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.trendingPageViewsItem{
  width: calc((100% / 6) - 10px);
  height: calc(100% - 24px);
  margin: 0px 5px;
  background: #2a2a2a;
}
.trendingPageViewsCompetitor{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;
}
.trendingPageViewsCompetitorValue{
	font-size: 26px;
  background: #363737;
  text-align: center;
  color: #cf3;
  font-weight: bold;
}
.trendingPageViewsCompetitorStatus{
	font-size: 10px;
  color: #595a5a;
  margin: 8px 0px;
  text-align: center;
  flex: 1;
}
.trendingPageViewsCompetitorImage{
	display: flex;
	justify-content: center;
	align-items: center;
}
.trendingPageViewsCompetitorImage img{
	width: 48px;
	height: 48px;
	border-radius: 50%;
}
.trendingPageViewsItemFull{
  width: calc((100% / 4) - 10px);
}
.trendingPageViewsItemFull .trendingPageViewsCompetitorImage img{
  width: 56px;
  height: 56px;
}