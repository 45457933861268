.sidebarCategoriesContainer{
	display: flex;
	flex-wrap: wrap;
}
.sidebarTag{
  border: 2px solid #cf3;
  margin: 4px;
  border-radius: 6px;
  padding: 4px;
  color: #b3b3b3;
  font-weight: lighter;
  font-size: 12px;
	display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px !important;
}
.sidebarTag[data-exclude=true]{
   border: 2px solid #b3b3b3;
}
.sidebarTagEdit{
	margin: 0px 4px;
	cursor: pointer;
}
.sidebarTagDelete{
	cursor: pointer;
}