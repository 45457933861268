#trendingMentions{
	width: 100%;
	height: calc((476px / 2) - 5px);
}
.trendingMentionsContainer{
	width: 100%;
	height: calc(100% - 60px);
}
.trendingMentionsContainer > div{
	width: 100%;
	height: 100%;
}
.trendingMentionsContainer ul{
	list-style: none;
	padding: 0;
	width: 100%;
	height: 100%;
}
.trendingMentionsContainer li a{
	color: #fff;
	font-size: 10px;
	margin: 10px 0px;
	display: block;
	text-align: left;
	text-decoration: none;
}
.trendingMentionsContainer li a p{
	margin: 0px;
	font-size: 10px;
}