.loadingAccountSpinerContainer{
  height: 80px;
  width: 80px;
  margin: auto;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.loadingAccountSpinerContainer img{
	height: 50px;
  width: 40px;
}
.loadingAccountSpiner{
  position: absolute;
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loadingAccountSpiner .lds-dual-ring {
  position: relative;
}
.loadingAccountSpiner .lds-dual-ring div {
  position: absolute;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #ccff33 transparent #ccff33 transparent;
  -webkit-animation: lds-dual-ring 1.6s linear infinite;
  animation: lds-dual-ring 1.6s linear infinite;
}
.loadingAccountSpiner .lds-dual-ring {
  width: 91px !important;
  height: 91px !important;
  -webkit-transform: translate(-45.5px, -45.5px) scale(0.455) translate(45.5px, 45.5px);
  transform: translate(-45.5px, -45.5px) scale(0.455) translate(45.5px, 45.5px);
}