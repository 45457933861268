#dashboard{
  padding-top: 80px;
  margin: 0 10px;
}
.dashboardContainer{
	width: calc(100% - 70px);
	margin-left: auto;
}
.postsContainerRow{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.postsContainerRow:before {
  content: "";
}

.postsContainerRow:after {
  content: "";
}
.postsContainerRowDummy{
	width: 368px;
	margin: 5px;
}
#dashboard .dashboardNotData{
  width: 100%;
  height: calc(100vh - 160px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #636363;
  text-transform: capitalize;
  font-size: 36px;
}
/* Range Picker */

.daterangepicker{
  background: #363737;
  border: #363737;
}
.daterangepicker .calendar-table{
  background: #363737;
  border: #363737;
}
.daterangepicker.show-ranges .drp-calendar.left{
  border: none;
}
.daterangepicker select.monthselect, 
.daterangepicker select.yearselect{
  background-color: transparent;
  color: #fff;
  border: none;
}
.daterangepicker .calendar-table .next span, 
.daterangepicker .calendar-table .prev span{
  border-color: #fff;
}
.daterangepicker td{
  border-radius: 0;
}
.daterangepicker td.off, 
.daterangepicker td.off.in-range, 
.daterangepicker td.off.start-date, 
.daterangepicker td.off.end-date{
  background-color: transparent;
}
.daterangepicker td.available:hover, 
.daterangepicker th.available:hover{
  border-radius: 0;
  color: #5d5d5d;
  background-color: #CF3;
  border-color: #CF3;
}
.daterangepicker td.in-range{
  border-radius: 0;
  color: #FFFFFF;
  background-color: rgba(204, 255, 51, 0.5);
  border-color: rgba(204, 255, 51, 0.5);
}
.daterangepicker td.active, 
.daterangepicker td.active:hover,
.daterangepicker td.start-date.end-date{
  border-radius: 0;
  color: #5d5d5d;
  background-color: #CF3;
  border-color: #CF3;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover{
  color: #5d5d5d;
  background-color: #CF3;
}
.daterangepicker:before,
.daterangepicker:after{
  border-bottom-color: #363737;
}
.daterangepickerDates{
  display: flex;
}
.daterangepickerDates .daterangepickerDate:first-child{
  margin-right: 2px;
}
.daterangepickerDate{
  background-color: #363737;
  border: none;
  color: #636363;
  height: 28px;
  display: flex;
  width: 148px;
  padding: 0px 10px;
  align-items: center;
  position: relative;
  top: 3px;
  cursor: pointer;
}
.daterangepicker .calendar-table th, 
.daterangepicker .calendar-table td{
  width: 32px;
  height: 32px;
  line-height: 0px;
  border: 0px;
}
.saleSearchKeywords{
  background-color: #363737;
  text-align: center;
  display: flex;
  margin-right: 20px;
  justify-content: center;
  align-content: center;
  font-size: 14px;
  color: #ceff33;
  font-weight: bold;
  padding: 5px 8px;
  cursor: pointer;
}
.saleSearchKeywords[data-active=true]{
  background-color: #ceff33;
  color: #363737;
}
.salesSearchFindIcon{
  cursor: pointer;
}
.saleBrandsMenuContainer{
  margin-right: 20px;
}
.saleBrandsMenuContainer .menu{
  height: 216px;
  width: 174px;
}
.saleBrandsMenuContainer .dropdownContainer div.menu{
  max-height: 240px;
  width: calc(100% + 60px);
}
.saleBrandsMenuContainer .dropdownContainer{
  background-color: #363737 !important;
  min-width: 100%;
  width: 100px;
}
.saleBrandsMenuControl{
  display: none;
}
.saleBrandsMenu{
  overflow: auto;
  width: 100%;
  background-color: #2a2a2a;
}
.saleBrandOption{
  cursor: pointer;
  border: none;
  height: auto;
  text-align: left;
  font-size: 1rem;
  text-transform: none;
  font-weight: 400;
  box-shadow: none;
  touch-callout: none;
  color: #fff;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.saleBrandOption div{
  height: 16px;
}
.saleBrandOption.active, 
.saleBrandOption.active:focus, 
.saleBrandOption.active:hover,
.saleBrandOption:focus, 
.saleBrandOption:hover,
.saleBrandOption[data-active=true] {
  background-color: #CCFF33 !important;
  background-image: none !important;
  color: #2a2a2a !important;
}
.competitor-dashboard .LoadingChartsContainer{
  margin-top: 30vh;
}