.homeContainer{
  padding-top: 100px;
}
.homeBrands{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.homeBrands[data-loading=false]{
  min-height: calc(100vh - 170px);
  align-items: center;
}
.brand{
  background: #363737;
  padding: 12px 28px;
  display: inline-block;
  margin: 10px;
  width: 400px;
  height: auto;
  text-align: center;
  position: relative;
}
.brand .timeZoneContainer{
  position: absolute;
  left: 0px;
  top: 0px;
  margin-top: 10px;
  margin-left: 28px;
}
.brandAccounts{
	display: flex;
	flex-wrap: wrap;
  justify-content: center;
}
.brandName {
	color: #fff;
  font-size: 20px;
  font-weight: 100;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.brandActions {
  display: flex;
  justify-content: space-between;
}
.homeHeader{
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.homeHeaderTitle{
  display: inline-block;
  width: auto;
  font-weight: bold;
  color: #636363;
  text-align: left; 
  margin-bottom: 0px;
}
.homeHeaderAddBrand{
  height: 10px;
}
.brands-added__title{
  display: inline-block;
  width: auto;
  font-weight: bold;
  color: #636363;
  text-align: left;
  margin-bottom: 0px;
  text-transform: capitalize;
  font-size: 30px !important;
}