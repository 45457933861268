#adsBestPerformingComp{
  
}
.adsBestPerformingCompContainer .postsContainer{
  display: flex;
  flex-direction: column;
}
.adsBestPerformingCompContainer .performingCompItem{
  width: 100%;
  display: flex;
  align-items: center;
  height: 384px;
  margin: 10px 0px;
}
.adsBestPerformingCompContainer .performingCompAvatar{
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin: 0px 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.adsBestPerformingCompContainer .performingCompPosts{
  flex: 1;
  display: flex;
  overflow: auto;
  justify-content: space-between;
  height: 100%;
}
.adsBestPerformingCompContainer .post{
  margin: 0px 5px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  background: #363737;
  width: 225px;
  border: 10px solid #2b2b2b;
  padding: 8px;
  cursor: pointer;
}
.adsBestPerformingCompContainer .post .postMessage{
  height: 50px;
  font-size: 12px;
  color: #636363;
  margin-bottom: 10px;
}
.adsBestPerformingCompContainer .post .postPicture{
  width: 190px;
  height: 190px;
  background-color: #2a2a2a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  margin-bottom: 10px;
}
.adsBestPerformingCompContainer .post .postPicture > div{
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.adsBestPerformingCompContainer .post .postDate{
  font-size: 12px;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  text-transform: capitalize;
  text-align: center;
  color: #636363;
  margin-bottom: 10px;
}
.adsBestPerformingCompContainer .post .postRelevance{
  font-size: 14px;
  color: #cf3;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 10px;
  text-align: center;
}
.adsBestPerformingCompContainer .post .postNumber{
  font-size: 12px;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  text-transform: capitalize;
  text-align: center;
  color: #636363;
}
.adsBestPerformingCompContainer  .postPlaceholder{
  min-width: 225px;
  height: 100%;
  margin: 0px 5px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  background: #2b2b2b;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}