.industrySettingsDialog {
  width: 90%;
}
.industrySettingsDialog .subtitle {
  font-size: 14px;
  border-bottom: 1px solid #CF3;
  font-weight: lighter;
}
.industrySettingsDialog .bp3-label {
  color: #636363 !important;
  font-size: 12px;
  width: 50%;
}
.industrySettingsDialog .bp3-input {
  color: #636363;
  width: 100%;
}
.industrySettingsDialog .bp3-dialog-header {
  border-bottom: 1px solid #CF3;
  margin: 0 20px;
  padding: 0;
}
.industrySettingsDialog .industrySettingsItem{
  height: 256px;
}
.industrySettingsDialog .bp3-dialog-header .bp3-heading {
  font-size: 25px;
  color: #696b6a;
  text-transform: uppercase;
  font-weight: lighter;
}
.industrySettingsImages{
  display: flex;
  flex-wrap: wrap;
}
.industrySettingsImagesItem{
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.industrySettingsFormContainer{
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 70px);
}
.industrySettingsForm{
  width: calc(50% - 10px);
  margin: 4px 5px;
}
.industrySettingsLabel{
  color: #636363;
  font-size: 12px;
  text-align: center;
  margin-top: 4px;
}

/* Form Full */

.industrySettingsFormFull{
  width: 100%;
}
.industrySettingsFormFull .bp3-form-content{
  display: flex;
  flex-direction: row-reverse;
}
.industrySettingsFormFull .bp3-form-content > .bp3-input-group{
  flex: 1;
}
.industrySettingsFormFull .industrySettingsLabel{
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.industrySettingsDelete{
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
}
.industrySettingsDelete i{
  cursor: pointer;
  color: #636363;
}
.industrySettingsSave {
  border: 2px solid #CEFF33;
  border-radius: 14px;
  font-size: 14px !important;
  width: 100px;
  display: block;
  text-align: center;
  margin: auto;
  margin-left: auto !important;
  margin-right: auto;
}
.industrySettingsImagesItemFull{
  width: calc(100% / 2);
}