
.retailDetails{
  width: 250px;
  padding: 10px;
  background: #2A2A2A;
  height: 100%;
}
.retailDetailsTitle{
	text-align: center;
  border-bottom: 2px solid #CF3;
  height: 28px;
  color: #636363;
  margin-bottom: 10px;
  font-weight: 900;
  font-size: 16px;
}
.retailDetailsItems{
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.retailDetailsItem {
  background: #363737;
  height: calc((100% / 3) - 10px);
  width: 96%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.retailDetailsItem p{
  text-align: center;
  margin: 0px;
}
.retailDetailsItemValue {
  font-size: 30px;
  font-weight: bold;
}
.retailDetailsItems .retailDetailsItem:nth-child(1) .retailDetailsItemValue{
  color: #CCFF33;
}
.retailDetailsItems .retailDetailsItem:nth-child(2) .retailDetailsItemValue{
  color: #4FABE6;
}
.retailDetailsItems .retailDetailsItem:nth-child(3) .retailDetailsItemValue{
  color: #E2474F;
}
.retailDetailsItemTitle {
  color: #636363;
  font-size: 14px;
}