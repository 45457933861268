#trendingHashtags{
  width: 100%;
  height: calc((476px / 2) - 5px);
}
.trendingHashtagsContainer{
  width: 100%;
  height: calc(100% - 70px);
}
.trendingHashtagsContainer > div{
  width: 100%;
  height: 100%;
}
.trendingHashtagsContainer ul{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.trendingHashtagsContainer li {
  height: 50%;  
  display: flex;
  align-items: center;
  justify-content: center;
}
.trendingHashtagsContainer li a{
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  width: 100%;
  padding: 0px 10px;
  text-align: center;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.trendingHashtagsContainer li:nth-child(1), 
.trendingHashtagsContainer li:nth-child(2) {
  width: calc(100% / 2);
}
.trendingHashtagsContainer li:nth-child(3), 
.trendingHashtagsContainer li:nth-child(4), 
.trendingHashtagsContainer li:nth-child(5) {
  width: calc(100% / 3);
}

