#adsInvestment{
  height: 476px;
  width: 100%;
}
.adsInvestmentContainer{
  height: 100%;
  height: calc(100% - 60px);
}
.adsInvestmentContainer > div{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.adsInvestmentItem{
  background-color: #2b2b2b;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc((434px - 86px ) / 3);
  width: 44%;
  margin: 10px;
}
.adsInvestmentData{
  font-size: 48px;
  color: #cf3;
  margin: 10px 0px;
  font-weight: bold;
  text-align: center;
}
.adsInvestmentItem img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 10px 0px;
  position: absolute;
  bottom: 0;
}
.adsInvestmentItemFull{
  height: calc((434px - 86px ) / 2);
}