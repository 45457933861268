#trendingBenchmark{
  width: 100%;
  height: 500px;
}
.trendingBenchmarkContainer{
  width: 100%;
  height: calc(100% - 70px);
}
.trendingBenchmarkContainer > div{
  width: 100%;
  height: 100%;
}
.trendingBenchmarkData{
  display: flex;
  margin: 10px;
}
.trendingBenchmarkDataAvatar{
  margin-right: 10px;
}
.trendingBenchmarkDataInfoContainer{
  width: calc(100% - 82px);
  overflow: auto;
}
.trendingBenchmarkDataInfo{
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.trendingBenchmarkItem{
  width: 170px;
  min-width: 170px;
  flex-basis: 170px;
  height: 94px;
  background-color: #2a2a2a;
  display: flex;
  flex-direction: column;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin: 0px 4px;
}
.trendingBenchmarkItem p{
  margin: 0px;
  text-align: center;
}
.trendingBenchmarkItemTitle{
  font-weight: bold;
  font-size: 16px;
  color:  #CF3;
}
.trendingBenchmarkItemValue{
  font-weight: bold;
  font-size: 26px;
}
.trendingBenchmarkItemPast{
  display: flex;
  width: 100%;
  align-items: flex-end;
  padding: 0px 10px;
  justify-content: space-between;
}
.trendingBenchmarkItemPastTitle{
  color:  #CF3;
  font-weight: 300;
  font-size: 12px;
  display: flex;
  height: 22px;
  align-items: center;
}
.trendingBenchmarkItemPastValue{
  font-weight: bold;
  font-size: 16px;
  display: flex;
  height: 22px;
  align-items: center;
}
.trendingBenchmarkItemPastValue p{
  display: flex;
  justify-content: center;
  align-items: center;
}
.trendingBenchmarkItemPastValue p i{
  display: inline-block;
  width: 10px;
  margin-left: 4px;
}
.trendingBenchmarkName{
  margin-bottom: 4px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 82px;
}
.trendingBenchmarkAvatar{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #CF3;
  margin: 4px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-color: #3b559e;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trendingBenchmarkItem.first{
  background-color: #CF3;
}
.trendingBenchmarkItem.first p,
.trendingBenchmarkItem.first i{
  color: #2a2a2a;
}
.trendingBenchmarkItemPastValue p i.fa-sort-up{
  margin-top: 6px;
}
.trendingBenchmarkItemPastValue p i.fa-sort-down{
  margin-bottom: 6px;
}
.trendingBenchmarkItemPending{
  display: flex;
  justify-content: center;
  align-items: center;
}
.trendingBenchmarkItemPending p{
  color: #636363;
  font-weight: 900;
  font-size: 12px;
  text-align: center;
}
.trendingBenchmarkItemPending .fa-clock{
  font-size: 30px;
  color: #636363;
}