#socialComposition{
  height: 238px;
  width: 100%;
}
.socialCompositionContainer {
  width: 100%;
  height: calc(100% - 60px);
}
.socialCompositionContainer > div{
  height: 100%;
  width: 100%;
}
.socialCompositionItems{
  height: 100%;
  width: 100%;
  display: flex;
}
.socialCompositionItem {
  flex: 1;
  padding: 10px;
  margin: 4px;
  background: #2A2A2A;
}
.socialCompositionItem table{
  width: 100%;
  height: 100%;
  display: flex;
}

#socialComposition td,
#socialComposition th{  
  border: .5px solid #4a4a4a;
}
#socialComposition thead {
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
#socialComposition tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  width: 100%;
  display: block;
}
#socialComposition tbody tr,
#socialComposition thead tr{
  height: 24px;
  display: flex;
}
#socialComposition tbody tr td:first-child{
  color: #CF3;
  font-weight: bold;
}
#socialComposition tbody tr td:last-child{
  font-weight: bold;
}
#socialComposition thead tr th:first-child,
#socialComposition tbody tr td:first-child{
  text-align: left;
  width: 70%;
}
#socialComposition thead tr th:last-child,
#socialComposition tbody tr td:last-child{
  text-align: right;
  width: 30%;
}
#socialComposition th,
#socialComposition td{
  padding: 4px;
}