#googleGranularAudience{
  height: 476px;
}
.googleGranularAudienceContainer{
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
#googleGranularAudience table{
  width: 100%;
}
#googleGranularAudience table,
#googleGranularAudience td,
#googleGranularAudience th{  
  border: .5px solid #4a4a4a;
}
#googleGranularAudience thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googleGranularAudience tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#googleGranularAudience tr{
  display: flex;
  width: 100%;
}
#googleGranularAudience thead tr th:first-child,
#googleGranularAudience tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#googleGranularAudience thead tr th:last-child,
#googleGranularAudience tbody tr td:last-child{
  text-align: right;
  flex-basis: 66px;
}
#googleGranularAudience th,
#googleGranularAudience td{
  padding: 4px;
}