#adsPaidMedia{
  height: 236px;
  width: 100%;
}
.adsPaidMediaContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.adsPaidMediaContainer > div{
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.adsPaidMediaItem{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #2b2b2b;
  margin: 4px;
  flex: 1;
  max-width: calc(100% / 4);
}
.adsPaidMediaInfo {
  flex: 1 1 auto; 
}
.adsPaidMediaAvatar {
  width: 100px;
  margin: 10px;
  margin-right: 0px;
}
.adsPaidMediaAvatar img {
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}
.adsPaidMediaData {
  color: #cf3;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-right: 4px;
}
.adsPaidMediaRank {
  font-size: 20px;
  color: #cf3;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}