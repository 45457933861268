#trendingRank{
  height: 476px;
  width: 100%;
}
.trendingRankContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.trendingRankContainer > div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.trendingRankItem{
  width: calc(100% / 3);
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.trendingRankItemIcon img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-position: center;
  background-size: cover; 
}
.trendingRankItemPosition{
  margin-bottom: 0px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.trendingRankItemPercent{
  color: #cf3;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}
.trendingRankItemValue{
  color: #595a5a;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
}

.trendingRankItemFull{
  width: calc(100% / 2);
}
.trendingRankItemFull .trendingRankItemIcon img{
  height: 130px;
  width: 130px;
}