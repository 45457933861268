#trendingTopTrends{
  width: 100%;
  height: 476px
}
.trendingTopTrendsContainer{
  width: 100%;
  height: calc(100% - 70px);
}
.trendingTopTrendsContainer > div{
  width: 100%;
  height: 100%;
}
.trendingTopTrendsContainer ul{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
}
.trendingTopTrendsContainer li{
  list-style: none;
  width: 50%;
  height: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.trendingTopTrendsContainer li a{
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  padding: 0px 10px;
  text-align: left;
}