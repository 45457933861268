#adsSentiment{
	width: 100%;
	height: calc((476px / 2) - 5px);
}
.adsSentimentContainer{
	width: 100%;
	height: calc(100% - 60px);	
}
.adsSentimentContainer > div{
	width: 100%;
	height: 100%;
}