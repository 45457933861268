#googleBroadAudience{
  height: 476px;
}
.googleBroadAudienceContainer{
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
#googleBroadAudience table{
  width: 100%;
}
#googleBroadAudience table,
#googleBroadAudience td,
#googleBroadAudience th{  
  border: .5px solid #4a4a4a;
}
#googleBroadAudience thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googleBroadAudience tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#googleBroadAudience tr{
  display: flex;
  width: 100%;
}
#googleBroadAudience thead tr th:first-child,
#googleBroadAudience tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#googleBroadAudience thead tr th:last-child,
#googleBroadAudience tbody tr td:last-child{
  text-align: right;
  flex-basis: 66px;
}
#googleBroadAudience th,
#googleBroadAudience td{
  padding: 4px;
}