.IndustryError{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
  color: #636363;
  text-transform: capitalize;
  font-size: 18px;
}