.NewWeek{
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  background: #2A2A2A;
  color: #fff;
  border: 1px solid #CCFF33;
  border-radius: 3px;
  width: 450px;
  z-index: 100000;
  height: 80vh; 
  max-height: 170px;
  padding: 0px;
}
.NewWeekHeader{
  background-color: transparent;
  border-radius: 0px;
}
.NewWeekHeader .bp3-heading{
  color: #fff;
}
.NewWeekProvider{
 margin-right: 10px;
}
.NewWeekHeader .fa-times{
  cursor: pointer;
}
.NewWeekContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
}
.bp3-dialog-footer .NewWeekAccept,
.bp3-dialog-footer .NewWeekAccept:hover,
.bp3-dialog-footer .NewWeekAccept:disabled{
  background: #CCFF33;
  box-shadow: none;
}
.bp3-dialog-footer .NewWeekCancel,
.bp3-dialog-footer .NewWeekCancel:hover,
.bp3-dialog-footer .NewWeekCancel:disabled{
  background: #db3737;
  box-shadow: none;
  color: #fff;
}
.NewWeekContainer .bp3-dialog-body{
  display: flex;
  justify-content: center;
}
.NewWeek .bp3-slider-progress.bp3-intent-primary{
  background-color: #CCFF33;
}
.NewWeek .bp3-slider-handle{
  background-color: #646464;
  background-image: none;
}
.NewWeek .bp3-slider-handle .bp3-slider-label{
  background: #CCFF33;
  color: #36383a;
}
.newWeekLoading{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  z-index: 100;
}