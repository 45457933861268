#googleFrequency{
  height: 476px;
}
.googleFrequencyContainer{
  height: calc(100% - 60px);
}
.googleFrequencyViewsTitle{
  color: #fff;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  margin: 0px;
}
.googleFrequencyViewsValue{
  font-weight: 700;
  color: #cf3;
  margin: 15px;
  font-size: 25px;
  text-align: center;
  margin: 0px;
}