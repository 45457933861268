#adsReach{
  width: 100%;
  height: 476px;
}
.adsReachContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.adsReachContainer > div{
  width: 100%;
  height: 100%;
}
.adsReachItems{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.adsReachItem{
  background-color: #2b2b2b;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc((434px - 86px ) / 2);
  width: 44%;
  margin: 10px;
}
.adsReachData{
  font-size: 24px;
  color: #cf3;
  margin: 10px 0px;
  font-weight: bold;
}
.adsReachItem img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 10px 0px;
  position: absolute;
  bottom: 0;
}