#adsTopInterestsCampaing{
	height: 476px;
  width: 100%;
}
.adsTopInterestsCampaingContainer {
  width: 100%;
  height: calc(100% - 60px);
}
.adsTopInterestsCampaingContainer > div{
  height: 100%;
  width: 100%;
}
.adsTopInterestsCampaingItems{
  height: 100%;
  width: 100%;
  display: flex;
}
.adsTopInterestsCampaingItem {
  flex: 1;
  max-width: 100%;
  padding: 10px;
  margin: 4px;
  background: #2A2A2A;
}
.adsTopInterestsCampaingItem table{
	width: 100%;
	height: 100%;
  display: flex;
}
#adsTopInterestsCampaing td,
#adsTopInterestsCampaing th{  
  border: .5px solid #4a4a4a;
}
#adsTopInterestsCampaing thead {
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
#adsTopInterestsCampaing tbody{
	color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
}
#adsTopInterestsCampaing tbody tr,
#adsTopInterestsCampaing thead tr{
  height: 24px;
  display: flex;
}
#adsTopInterestsCampaing tbody tr td:first-child{
  color: #CF3;
  font-weight: bold;
}
#adsTopInterestsCampaing tbody tr td:last-child{
  font-weight: bold;
}
#adsTopInterestsCampaing thead tr th:first-child,
#adsTopInterestsCampaing tbody tr td:first-child{
	text-align: left;
  width: 100%;
}
#adsTopInterestsCampaing thead tr th:last-child,
#adsTopInterestsCampaing tbody tr td:last-child{
	text-align: left;
  width: 100%;
}
#adsTopInterestsCampaing th,
#adsTopInterestsCampaing td{
	padding: 4px;
}