#socialAnalyticsPanel{
  height: 476px;
}
#socialAnalyticsPanel .socialAnalyticsPanelContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.socialAnalyticsPanelMetrics{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.socialAnalyticsPanelMetric{
  width: 100%;
  height: calc((100% / 6) - 10px);
  margin: 5px 0px;
  display: flex;
  justify-content: space-around;
}
.socialAnalyticsPanelCategories,
.socialAnalyticsPanelValue{
  width: calc((100% / 2) - 40px);
  height: 100%;
}
.socialAnalyticsPanelValue{
  background-color: #2b2b2b;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 26px;
}
.socialAnalyticsPanelCategories{
  display: flex;
}
.socialAnalyticsPanelCategories div{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: #ccff33;
  background-color: #2b2b2b;
  margin: 0px 4px;
  cursor: pointer;
}
.socialAnalyticsPanelCategories div[data-er],
.socialAnalyticsPanelCategories div[data-only='true']{
  font-size: 26px;
}
.socialAnalyticsPanelCategories div.active{
  background-color: #ccff33;
  color: #2b2b2b;
}