.listAds{
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  background: #2A2A2A;
  color: #fff;
  border: 1px solid #CCFF33;
  border-radius: 3px;
  width: 450px;
  padding: 10px;
  z-index: 100000;
  height: 80vh; 
  max-height: 170px;
}
.listAdsHeader{
  background-color: transparent;
  border-radius: 0px;
}
.listAdsHeader .bp3-heading{
  color: #fff;
  text-transform: capitalize;
}
.listAdsProvider{
 margin-right: 10px;
}
.listAdsHeader .fa-times{
  cursor: pointer;
}
.listAdsContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bp3-dialog-footer .listAdsSelect,
.bp3-dialog-footer .listAdsSelect:hover,
.bp3-dialog-footer .listAdsSelect:disabled{
  background: #CCFF33;
  box-shadow: none;
}
.listAdsContainer .bp3-dialog-body{
  display: flex;
  justify-content: center;
}