.titleChart{
  text-align: center;
  border-bottom: 2px solid #CF3;
  height: 40px;
  color: #636363;
  margin-bottom: 10px;
  font-weight: 900;
  font-size: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleChart .titleChartTitle{
	margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.titleChart i{
  font-family: 'Font Awesome 5 Free' !important;
  cursor: pointer;
}
.titleChartTooltip{
  
}