.ErrorChart{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
.ErrorChart p{
  color: #636363;
  font-weight: 900;
  font-size: 16px;
}