.socialTopicDataItem{
	padding: 4px;
	position: relative;
}
.socialTopicDataItemInfo p{
  text-align: left;
  font-weight: 100;
  font-size: 20px;
  color: #636363;
  margin: 0px;
}
.socialTopicDataCount{
	font-size: 14px;
}
.socialTopicDataItemForm{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}
.socialTopicDataItemFormInput{
	height: 30px;
	flex: 1;
}
.socialTopicDataItemFormInput input[type=text]{
  border: none;
  background: #363737;
  color: #fff;
  text-align: center;
  font-weight: lighter;
  height: 100%;
  width: 100%;
}
.socialTopicDataItemFormAdd{
	height: 30px;
	width: 30px;
	color: #CF3;
	cursor: pointer;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.socialTopicDataItemWords{
	max-height: 238px;
	overflow: auto;
	margin-top: 10px;
}
.socialTopicDataWord{
	display: flex;
	color: #5d5c5c;
	font-weight: 400;
	padding: 6px 4px;
	border-bottom: 1px solid #5d5c5c;
	position: relative;
}
.socialTopicDataWord div{
	flex: 1;
	text-align: right;
}
div.socialTopicDataWordName{
  text-align: left;
}
.socialTopicDataWordDelete i{
	cursor: pointer;
	color: #e63636;
	font-size: 18px;
}
.socialTopicDataItemDelete{
	position: absolute;
	top: 0px;
	right: 0px;
	margin: 6px;
}
.socialTopicDataItemDelete i{
	cursor: pointer;
	color: #e63636;
	font-size: 18px;
}