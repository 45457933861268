#socialBestHashtags{
  height: 476px;
}
.socialBestHashtagsContainer{
  height: calc(100% - 60px);
}
#socialBestHashtagsChart{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  height: 264px;
  padding: 0px;
}
#socialBestHashtagsChart li {
  height: 50%;  
  display: flex;
  align-items: center;
  justify-content: center;
}
#socialBestHashtagsChart li p{
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  width: 100%;
  padding: 0px 10px;
  text-align: center;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
#socialBestHashtagsChart li:nth-child(1), 
#socialBestHashtagsChart li:nth-child(2) {
  width: calc(100% / 2);
}
#socialBestHashtagsChart li:nth-child(3), 
#socialBestHashtagsChart li:nth-child(4), 
#socialBestHashtagsChart li:nth-child(5) {
  width: calc(100% / 3);
}

#socialBestHashtags table{
  width: 100%;
}
#socialBestHashtags table,
#socialBestHashtags td,
#socialBestHashtags th{  
  border: .5px solid #4a4a4a;
}
#socialBestHashtags thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#socialBestHashtags tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: hidden;
}
#socialBestHashtags tr{
  display: flex;
  width: 100%;
}
#socialBestHashtags thead tr th:first-child,
#socialBestHashtags tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#socialBestHashtags thead tr th:last-child,
#socialBestHashtags tbody tr td:last-child{
  text-align: right;
  flex-basis: 66px;
}
#socialBestHashtags th,
#socialBestHashtags td{
  padding: 4px;
}