input[type=file].industryImageInput{
	display: none;
}
.industryImageLabel{
	color: #636363;
	font-size: 12px;
	text-align: center;
}
.industryImagePreview{
	width: 56px;
	height: 56px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 50%;
	position: relative;
	border: 2px solid #cf3;
	margin: 4px;
	cursor: pointer;
	background-color: #2A2A2A;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #636363;
	font-size: 12px;
}

input[type=file].industryImageInput:disabled + label .industryImagePreview{
	cursor: default;
}