#erComp{
  height: 476px;
  width: 100%;
}
.erCompContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.erCompContainer > div{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
}
.erCompItem{
  width: calc(100% / 3);
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.erCompItemIcon img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-position: center;
  background-size: cover; 
}
.erCompItemPosition{
  margin-bottom: 0px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.erCompItemValue{
  color: #cf3;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  text-align: center;
}
.erCompItemFull{
  width: calc(100% / 2);
}
.erCompItemFull .erCompItemIcon img{
  height: 130px;
  width: 130px;
}