.brandAccount{
	cursor: pointer;
}
.brandAccountsProvider{
	margin-bottom: 4px;
	text-align: center;
	font-weight: bold;
	color: #fff;
	font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.brandAccounts{
  margin-bottom: 10px;
}
.brandAccounts > div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25%;
}
.brandAccountsIcon > div[class*='brandAccounts']{
	width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #CF3;
  margin: 4px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
}

.brandAccountsIcon > div.brandAccountsfacebook,
.brandAccountsIcon > div.brandAccountsads{
	background-color: #3b559e;
}
.brandAccountsIcon > div.brandAccountstwitter{
	background-color: #51afe9;
}
.brandAccountsIcon > div.brandAccountsinstagram{
	background-color: #a33190;
}
.brandAccountsIcon > div.brandAccountsgoogle{
	background-color: #d94e31;
}
.brandAccountsIcon > div.brandAccountslinkedin{
	background-color: #2378bb;
}
.brandAccountsIcon > div.brandAccountsyoutube{
  background-color: #FF0000;
}
.brandAccountAvatar > div[class*='brandAccountAvatar']{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #CF3;
  margin: 4px 2px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
div[class*='brandAccountsActions']{
  position: absolute;
  width: 22px;
  height: 22px;
  border: 1px solid #cdff33;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  cursor: pointer;
  background-color: #2A2A2A;
  font-size: 12px;
}

.brandAccountsActionsEdit{
  right: 0px;
}
.brandAccountsActionsDelete{
  left: 0px;
}