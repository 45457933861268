#social{
  padding-top: 80px;
  margin: 0 10px;
}
.socialContainer{
	width: calc(100% - 70px);
	margin-left: auto;
}
.overview-section__title{
  font-weight: bold;
  color: #636363;
  text-align: left;
  margin: 10px 6px;
  text-transform: capitalize;
  font-size: 20px !important;
  text-transform: uppercase !important;
}
.notData{
	width: 100%;
  height: calc(100vh - 160px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #636363;
  text-transform: capitalize;
  font-size: 36px;
}