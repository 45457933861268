#socialTopicData{
  width: 100%;
  height: 841px;
}
.socialTopicDataContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.socialTopicDataContainer > div{
  width: 100%;
  height: 100%;
}
.socialTopicDatasSections{
  padding: 10px;
}
.socialTopicDatasSectionsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialTopicDatasSectionsContainer > div{
  display: flex;
  overflow: auto;
}
.socialTopicDataSection{
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  height: 350px;
  background: #2b2b2b;
  margin: 4px;
}
.socialTopicDataSectionAdd{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.socialTopicDataSectionAdd i{
  font-size: 50px;
  color: #cf3;
}
