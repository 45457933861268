#trendingSearchTrend{
	height: 476px;
}
.trendingSearchTrendContainer{
	width: 100%;
  height: calc(100% - 70px);
}
.trendingSearchTrendContainer > div{
	width: 100%;
	height: 100%;
}
#trendingSearchTrend #widget{
	height: 400px
}
#trendingSearchTrend #widget iframe{
  height: 394px !important;
  margin-top: 8px;
}