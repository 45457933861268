#trendingLinksInCount{
	height: calc((476px / 2) - 5px);
	width: 100%;
}
.trendingLinksInCountContainer{
	height: calc(100% - 68px);
	width: 100%;
}
.trendingLinksInCountContainer > div{
	width: 100%;
	height: 100%;
	display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.trendingLinksInCountItem{
	width: calc(100% / 3);
	height: calc(50% - 10px);
	padding: 4px;
}
.trendingLinksInCountItemContainer{
	background-color: #2a2a2a;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.trendingLinksInCountInfo {
  flex: 1;
  padding: 4px;
}
.trendingLinksInCountImage{
	padding: 4px;
}
.trendingLinksInCountImage img{
	width: 48px;
  height: 48px;
  border-radius: 50%;
}
.trendingLinksInCountInfo{
	display: flex;
  align-items: center;
  justify-content: center;
}
.trendingLinksInCountValue{
	color: #cf3;
  font-size: 28px;
  font-weight: bold;
  margin: 0px;
}
.trendingLinksInCountItemFull{
	width: calc(100% / 2);
}
.trendingLinksInCountItemFull .trendingLinksInCountImage img{
	width: 56px;
	height: 56px;
}
.trendingLinksInCountItemFull .trendingLinksInCountValue{
	font-size: 36px;
}