.socialNetworkContainer{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.accountSocialNetwork{
	width: 56px;
	height: 56px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 50%;
	position: relative;
	border-width: 2px;
	border-style: solid;
	margin: 4px;
}
.accountSocialNetwork i{
  position: absolute;
  top: -8px;
  right: -2px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accountSocialNetwork[provider='facebook'],
.accountSocialNetwork[provider='ads'],
.accountSocialNetwork[provider='facebook'] i,
.accountSocialNetwork[provider='ads'] i{
	border-color: #405e96;
	background-color: #405e96;
}	
.accountSocialNetwork[provider='instagram'],
.accountSocialNetwork[provider='instagram'] i{
	border-color: #a7328b;
	background-color: #a7328b;
}	

.accountSocialNetwork[provider='linkedin'],
.accountSocialNetwork[provider='linkedin'] i{
	border-color: #2378bb;
	background-color: #2378bb;
}	

.accountSocialNetwork[provider='twitter'],
.accountSocialNetwork[provider='twitter'] i{
	border-color: #51afe9;
	background-color: #51afe9;
}	

.accountSocialNetwork[provider='google'],
.accountSocialNetwork[provider='google'] i{
	border-color: #d94e31;
	background-color: #d94e31;
}	

.accountSocialNetwork[provider='youtube'],
.accountSocialNetwork[provider='youtube'] i{
  border-color: #FF0000;
  background-color: #FF0000;
}