#googleAgeSS{
  height: 476px;
}
.googleAgeSSContainer{
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
#googleAgeSS table{
  width: 100%;
}
#googleAgeSS table,
#googleAgeSS td,
#googleAgeSS th{  
  border: .5px solid #4a4a4a;
}
#googleAgeSS thead {
  color: #CF3;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googleAgeSS tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#googleAgeSS tr{
  display: flex;
  width: 100%;
}
#googleAgeSS thead tr th,
#googleAgeSS tbody tr td{
  text-align: center;
  flex: 1;
}
#googleAgeSS thead tr th:first-child,
#googleAgeSS tbody tr td:first-child{
  text-align: left;
  flex: 2;
}
#googleAgeSS th,
#googleAgeSS td{
  padding: 4px;
}