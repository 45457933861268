#googleBrowser{
  height: 476px;
}
.googleBrowserContainer{
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
#googleBrowser table{
  width: 100%;
}
#googleBrowser table,
#googleBrowser td,
#googleBrowser th{  
  border: .5px solid #4a4a4a;
}
#googleBrowser thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googleBrowser tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#googleBrowser tr{
  display: flex;
  width: 100%;
}
#googleBrowser thead tr th:first-child,
#googleBrowser tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#googleBrowser thead tr th:last-child,
#googleBrowser tbody tr td:last-child{
  text-align: right;
  flex-basis: 90px;
}
#googleBrowser th,
#googleBrowser td{
  padding: 4px;
}