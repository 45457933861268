#trendingVideos{
	height: calc((476px / 2) - 5px);
	width: 100%;
}
.trendingVideosContainer{
	width: 100%;
	height: calc(100% - 60px);
}
.trendingVideosContainer > div{
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.trendingVideosContainer .trendingVideosItem{
	width: calc(50% - 8px);
	height: calc(50% - 8px);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.trendingVideosContainer .trendingVideosItem img{
	width: 100%;
}