.header,
.headerBrand,
.headerSocial {
  height: 80px;
}
.header {
  background-color: #2A2A2A;
  border-bottom: 2px solid #CF3;
}
.headerBrandImage {
  cursor: pointer;
}
.headerBrandImage img {
  height: 60px;
}
.headerBrandUserAvatar{
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0px 10px;
}
.headerBrandPageAvatar{
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.headerUser{
  float: left;
  margin: 0 10px;
  text-align: right;
}
.headerUser p{
  margin: 0px;
  text-align: right;
}
.headerUserUsername {
  font-size: 15px;
  color: #FFF;
  font-weight: bold;
  width: 100%;
}
.headerUserLogout{
  font-size: 15px;
  color: #FFF;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
}
.headerPageName{
  font-size: 15px;
  color: #CF3;
  font-weight: bold;
  width: 100%;
}
.headerSettings{
  cursor: pointer;
}