#adsDistribution{
	height: 476px;
	width: 100%;
}
.adsDistributionContainer{
	position: relative;
	width: 100%;
	height: calc(100% - 60px);
}
.adsDistributionContainer > div{
	width: 100%;
	height: 100%;
}