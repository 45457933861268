#socialPostsDistribution{
  height: 476px;
  width: 100%;
}
.socialPostsDistributionContainer{
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
}
.socialPostsDistributionContainer > div{
	width: 100%;
	height: 100%;
}