.adsScoreAds{
  width: 400px;
  padding: 10px 0px;
  height: 100%;
  position: relative;
}
.adsScoreAdsContainer{
  width: 100%;
  position: relative;
}
.adsScoreAds .swiper-pagination{
  bottom: 10px;
}
.adsScoreAds .swiper-pagination-bullet-active{
  background-color: #ccff33;
}
.adsScoreAdsPrev{
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 0px;
  z-index: 1;
  display: inline-block;
  margin-left: 30px;
}
.adsScoreAdsNext{
  text-align: center;
  position: absolute;
  bottom: 10px;
  right: 0px;
  z-index: 1;
  display: inline-block;
  margin-right: 30px;
}
.adsScoreAdsPrev i,
.adsScoreAdsNext i{
  cursor: pointer;
  color: #ccff33;
  font-size: 24px;
}
#adsScore .postContainer {
  position: relative;
  width: 368px;
  height: 336px;
  display: inline-block;
  margin: 5px;
  background: #2b2b2b !important;
  padding: 10px;
}
#adsScore .postIcon {
  width: 0;
  height: 0;
  border-right: 15px solid #ababab;
  border-top: 15px solid #ababab;
  border-left: 15px solid rgba(0, 0, 0, 0);
  border-bottom: 15px solid rgba(0, 0, 0, 0);
  right: 0;
  top: 0;
  position: absolute !important;
  margin: 0 !important;
}
#adsScore .postIcon i {
  color: #2a2a2a;
  position: relative;
  bottom: 15px;
  left: 0px;
  font-size: 13px;
}
#adsScore .postImage {
  width: 226px;
  height: 226px;
  vertical-align: top;
  overflow: hidden;
  background: url(../../../../../assets/img/logo.png) no-repeat center center;
  background-color: #2A2A2A;
  background-size: 20px 20px;
  display: flex;
  float: left;
  align-items: center;
}
#adsScore .postImage a{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
#adsScore .postImage img {
  width: 100%;
}
#adsScore .postExtras {
  width: calc(100% - 226px);
  padding: 0;
  display: inline-block;
  height: 226px;
  float: right;
  text-align: center;
}
#adsScore .postExtrasEngagment {
  margin-bottom: 10px;
}
#adsScore .postExtrasEngagment h6 {
  font-size: 13px;
  font-weight: bold;
  color: #CF3;
  margin-bottom: 0;
}
#adsScore .postExtrasEngagment p {
  font-weight: lighter;
  font-size: 16px;
  color: #BABABA;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#adsScore .postExtrasCategory .dropdownContainer{
  min-width: 100%;
  width: 100px;
}
#adsScore .postInsightsInfo {
  display: inline-block;
  float: left;
  width: 50%;
  margin: 5px 0px 0;
}
#adsScore .postDate {
  text-align: right;
  font-weight: bold;
  color: #636363;
  margin-bottom: 10px;
  position: relative;
}
#adsScore .postDate .logoSmartPost {
  width: 20px;
  position: absolute;
  left: 0;
}
#adsScore .postMessage {
  height: 58px;
  font-weight: bold;
  font-size: 14px;
  color: #636363;
  text-align: left;
  margin: 4px 0px;
  padding: 0;
}
#adsScore .post-topContainer{
  display: inline-block;
  width: 100%;
}