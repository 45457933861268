#retailInvestment{
  height: 386px;
  width: 100%;
}
.retailInvestmentContainer{
  height: calc(100% - 68px);
  width: 100%;
}
.retailInvestmentContainer > div{
  width: 100%;
  height: 100%;
}
.retailInvestmentData{
  display: flex;
  width: 100%;
  height: 100%;
}
.retailChartContainer{
  width: calc(100% - 250px);
  margin-left: 10px;
}