#googleLanguage{
	height: calc((476px / 2) - 5px);
}
.googleLanguageChart{
	width: 100%;
	height: 114px;
	display: flex;
	flex-direction: row;
}
.googleLanguageChart div{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: .5px solid #fff;
	overflow: hidden;
}
.googleLanguageChart div p{
	margin-bottom: 0px;
	margin-top: 10px;
}
.googleLanguageChart div:nth-child(1){
	background-color: rgb(204, 255, 51);
  color: rgb(54, 56, 58);
}
.googleLanguageChart div:nth-child(2){
	background-color: rgb(226, 71, 79);
  color: rgb(255, 255, 255);
}
.googleLanguageTitles{
	display: flex;
	justify-content: space-between;
	padding: 10px;
	font-weight: bold;
}