#googleGender{
	height: calc((476px / 2) - 5px);
}
.googleGenderChart{
	width: 100%;
	height: 140px;
	display: flex;
	flex-direction: row;
}
.googleGenderfemale,
.googleGendermale{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: .5px solid #fff;
}
.googleGenderfemale i,
.googleGendermale i{
	font-size: 60px;
}
.googleGenderfemale p,
.googleGendermale p{
	margin-bottom: 0px;
	margin-top: 10px;
}
.googleGenderfemale{
	background-color: #ff8bd8;
}
.googleGendermale{
	background-color: #4fabe6;
}