#adsInteractions{
  height: 476px;
}
.adsInteractionsContainer{
  height: calc(100% - 60px);
}
#adsInteractionsChart{
	height: 100%;
}
#adsInteractions table{
	width: 100%;
}
#adsInteractions table,
#adsInteractions td,
#adsInteractions th{  
  border: .5px solid #4a4a4a;
}
#adsInteractions thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#adsInteractions tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: hidden;
}
#adsInteractions tr{
  display: flex;
  width: 100%;
}
#adsInteractions thead tr th:first-child,
#adsInteractions tbody tr td:first-child{
  text-align: left;
  flex: 1;
  text-transform: capitalize;
}
#adsInteractions thead tr th:last-child,
#adsInteractions tbody tr td:last-child{
  text-align: right;
  width: 52px;
}
#adsInteractions th,
#adsInteractions td{
  padding: 4px;
}