.contextMenu{
	z-index: 10000;
}
.contextMenuItem{
	width: 125px;
  color: rgb(255, 255, 255);
  font-family: Helvetica;
  font-size: 12px;
  background-color: rgb(89, 89, 89);
  border-top: 1px solid rgb(89, 89, 89);
  border-left: 0px solid rgb(89, 89, 89);
  border-right: 0px solid rgb(89, 89, 89);
  padding: 4px 20px 4px 15px;
  text-align: left;
  cursor: pointer;
}