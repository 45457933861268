#adsTotalActivity{
  height: 476px;
  width: 100%;
}
.adsTotalActivityContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.adsTotalActivityContainer > div{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
#adsTotalActivity .adsTotalActivityItem{
  width: calc(50% - 20px);
  height: calc(50% - 20px);
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#adsTotalActivity .adsTotalActivityIcon{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 26px;
}
#adsTotalActivity .adsTotalActivityValue{
  font-size: 36px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px;
}
#adsTotalActivity .adsTotalActivityName{
  color: #636363;
  font-weight: 700;
  font-size: 14px;
  margin: 5px;
  text-align: center;
  text-transform: capitalize;
}
#adsTotalActivity .adsTotalActivityimpressions{
  background-color: #cf3;
  color: #53585f
}
#adsTotalActivity .adsTotalActivityengagements{
  background-color: #69ade1;
}
#adsTotalActivity .adsTotalActivityads{
  background-color: #f79a3a;
}
#adsTotalActivity .adsTotalActivityvideo_views{
  background-color: #ea5f61;
}