#socialBestPaidPosts{
  height: 404px;
  width: 100%;
}
.socialBestPaidPostsContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.socialBestPaidPostsContainer > div{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.socialBestPaidPostsContainer .postsContainer{
  display: flex;
  overflow: auto;
  overflow-y: hidden;
}
.socialBestPaidPostsContainer .post{
  margin: 5px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  background: #363737;
  width: 225px;
  border: 10px solid #2b2b2b;
  padding: 8px;
  height: 330px;
  cursor: pointer;
  position: relative;
}
.socialBestPaidPostsContainer .post .postMessage{
  font-size: 12px;
  color: #636363;
  margin-bottom: 10px;
}
.socialBestPaidPostsContainer .post .postPicture{
  width: 190px;
  height: 190px;
  background-color: #2a2a2a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  margin-bottom: 10px;
}
.socialBestPaidPostsContainer .post .postPicture > div{
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.socialBestPaidPostsContainer .post .postDate{
  font-size: 12px;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  text-transform: capitalize;
  text-align: center;
  color: #636363;
  margin-bottom: 10px;
}
.socialBestPaidPostsContainer .post .postRelevance{
  font-size: 14px;
  color: #cf3;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 10px;
  text-align: center;
}
.socialBestPaidPostsContainer .post .postNumber{
  font-size: 12px;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  text-transform: capitalize;
  text-align: center;
  color: #636363;
}
.socialBestPaidPostsContainer .postPlaceholder{
  min-width: 225px;
  height: calc(100% - 10px);
  margin: 5px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  background: #2b2b2b;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 330px;
}