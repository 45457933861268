#socialInteractions{
  width: 100%;
  height: 476px;
}
.socialInteractionsContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.socialInteractionsContainer > div{
  width: 100%;
  height: 100%;
}
#socialInteractionsChart{
	height: 100%;
}
#socialInteractions table{
	width: 100%;
}
#socialInteractions table,
#socialInteractions td,
#socialInteractions th{  
  border: .5px solid #4a4a4a;
}
#socialInteractions thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#socialInteractions tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: hidden;
}
#socialInteractions tr{
  display: flex;
  width: 100%;
}
#socialInteractions thead tr th:first-child,
#socialInteractions tbody tr td:first-child{
  text-align: left;
  flex: 1;
  text-transform: capitalize;
}
#socialInteractions thead tr th:last-child,
#socialInteractions tbody tr td:last-child{
  text-align: right;
  width: 52px;
}
#socialInteractions th,
#socialInteractions td{
  padding: 4px;
}