#adsDemografics{
  height: 476px;
  width: 100%;
}
.adsDemograficsContainer{
  height: calc(100% - 60px);
  width: 100%;
}
.adsDemograficsContainer > div{
  width: 100%;
  height: 100%;
}
#adsDemografics .demographicsSubtitle{
	display: flex;
	justify-content: space-between;
}
#adsDemografics .demographicsSubtitleText{
  font-weight: 900;
  font-size: 17px;
  color: #636363;
  margin-bottom: 10px;
  text-align: center;
}
#adsDemografics .demographicsSubtitleValue{
	color: #bae831;
  font-size: 35px;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;
}
#adsDemografics .demographicsFlags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 152px;
  max-width: 350px;
  margin: auto;
}
#adsDemografics .demographicsFlagsImage {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 78px;
  height: 52px;
  margin: 4px;
  cursor: pointer;
}
#adsDemografics .demographicsCities {
  margin-top: 30px;
}
#adsDemografics .demographicsCities table{
  width: 100%;
}
#adsDemografics table,
#adsDemografics td,
#adsDemografics th{  
  border: .5px solid #4a4a4a;
}
#adsDemografics thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#adsDemografics tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: hidden;
}
#adsDemografics tr{
  display: flex;
  width: 100%;
}
#adsDemografics thead tr th:first-child,
#adsDemografics tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#adsDemografics thead tr th:last-child,
#adsDemografics tbody tr td:last-child{
  text-align: right;
  width: 64px;
}
#adsDemografics th,
#adsDemografics td{
  padding: 4px;
}