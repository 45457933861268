#socialPersons{
  height: calc((476px / 2) - 5px);
}
.socialPersonsContainer{
	width: 100%;
	height: calc(100% - 60px);	
}
.socialPersonsContainer > div{
	width: 100%;
	height: 100%;
}
.socialPersonsgender{
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: row;
	overflow: hidden;
}
.socialPersonsgenderFemale,
.socialPersonsgenderMale{
	height: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: .5px solid #fff;
}
.socialPersonsgenderFemale i,
.socialPersonsgenderMale i{
	font-size: 42px;
}
.socialPersonsgenderFemale p,
.socialPersonsgenderMale p{
	margin-bottom: 0px;
	margin-top: 2px;
}
.socialPersonsgenderFemale{
	background-color: #ff8bd8;
}
.socialPersonsgenderMale{
	background-color: #4fabe6;
}
.socialPersonsNotData{
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: row;
	overflow: hidden;
  font-weight: bold;
  color: #636363;
  text-transform: capitalize;
  font-size: 36px;
}
.socialPersonsRange{
	color: #636363;
	font-weight: bold;
	display: flex;
  justify-content: space-between;
  font-size: 22px;
  align-items: center;
  color: #fff;
  height: 80px;
  padding: 0px 26px;
}
.socialPersonsRange p:first-child{
  font-size: 34px;
  margin: 0;
}
.socialPersonsRange p:last-child{
  font-size: 40px;
  margin: 0;
}