#adsAudience{
	height: 476px;
}
.adsAudienceContainer{
	height: calc(100% - 60px);
}
#adsAges{
	 height: calc(50% - 5px);
}
#adsGender{
	 height: calc(50% - 5px);
}
.adsAudiencegender{
	width: 100%;
	height: 130px;
	display: flex;
	flex-direction: row;
}
.adsAudiencegenderFemale,
.adsAudiencegenderMale{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: .5px solid #fff;
}
.adsAudiencegenderFemale i,
.adsAudiencegenderMale i{
	font-size: 60px;
}
.adsAudiencegenderFemale p,
.adsAudiencegenderMale p{
	margin-bottom: 0px;
	margin-top: 10px;
}
.adsAudiencegenderFemale{
	background-color: #ff8bd8;
}
.adsAudiencegenderMale{
	background-color: #4fabe6;
}