#socialBestDays{
  height: 476px;
  width: 100%;
}
.socialBestDaysContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.socialBestDaysContainer > div{
  width: 100%;
  height: 100%;
}
#socialBestDays table{
  width: 100%;
}
#socialBestDays table,
#socialBestDays td,
#socialBestDays th{  
  border: .5px solid #4a4a4a;
}
#socialBestDays thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#socialBestDays tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: hidden;
}
#socialBestDays tr{
  display: flex;
  width: 100%;
}
#socialBestDays thead tr th,
#socialBestDays tbody tr td{
  text-align: center;
  flex: 1;
}
#socialBestDays thead tr th:first-child,
#socialBestDays tbody tr td:first-child{
  text-align: left;
}
#socialBestDays th,
#socialBestDays td{
  padding: 4px;
}