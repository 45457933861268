.topicData-shell {
  flex-wrap: nowrap;
  overflow-x: scroll; }

.topicData-item {
  background: #2b2b2b;
  padding: 5px;
  height: 350px; }
  .topicData-item__addIcon {
    font-size: 50px !important;
    color: #cf3; }
  .topicData-item__last > div {
    text-align: center;
    cursor: pointer; }

.topic-item__title, .topic-item__count {
  height: 20px;
  text-align: left;
  margin: 3px;
  font-weight: 100;
  font-size: 20px;
  color: #636363; }

.topic-item__count {
  font-size: 14px; }

.topic-item__word {
  height: 28px;
  margin-top: 13px; }
  .topic-item__word .bp3-icon {
    cursor: pointer; }
    .topic-item__word .bp3-icon path {
      fill: #CF3; }
  .topic-item__word .bp3-icon:hover path {
    fill: #fff; }

.topic-item__input {
  border: none;
  background: #363737;
  color: #fff;
  text-align: center;
  font-weight: lighter;
  height: 28px; }

.topic-item__list {
  padding: 0; }
  .topic-item__list--item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    color: #5d5c5c;
    font-weight: 400;
    padding: 11px 0px;
    border-bottom: 1px solid #5d5c5c;
    position: relative; }
  .topic-item__list--name, .topic-item__list--total, .topic-item__list--delete {
    flex: 2;
    text-align: right; }
  .topic-item__list--name {
    text-align: left; }
  .topic-item__list--delete i {
    font-size: 18px;
    color: #e63636;
    cursor: pointer; }

.dialog-add__error {
  font-size: 10px;
  color: #cf3;
  text-align: center; }
