#trendingFacebook{
  height: 386px;
  width: 100%;
}
.trendingFacebookContainer{
  height: 100%;
  width: 100%;
}
.trendingFacebookContainer > div{
  width: 100%;
  height: 100%;
}
.trendingFacebookContainer .postsContainer{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.trendingFacebookContainer .trendingFacebookContainerItem{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}
.trendingFacebookContainer .trendingFacebookContainerIcon{
  font-size: 150px;
  display: flex;
  width: 200px;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #929292;
}
.trendingFacebookContainer .trendingFacebookContainerPosts{
  flex: 1;
  display: flex;
  overflow: auto;
  justify-content: space-between;
  align-items: center;
}
.trendingFacebookContainer .post{
  margin: 5px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  background: #363737;
  width: 225px;
  border: 10px solid #2b2b2b;
  padding: 8px;
  height: 332px;
}
.trendingFacebookContainer .post .postMessage{
  height: 40px;
  font-size: 12px;
  color: #636363;
  margin-bottom: 10px;
}
.trendingFacebookContainer .post .postPicture{
  width: 190px;
  height: 190px;
  background-color: #2a2a2a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  margin-bottom: 10px;
}
.trendingFacebookContainer .post .postPicture > div{
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.trendingFacebookContainer .post .postDate{
  font-size: 12px;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  text-transform: capitalize;
  text-align: center;
  color: #636363;
  margin-bottom: 10px;
}
.trendingFacebookContainer .post .postRelevance{
  font-size: 14px;
  color: #cf3;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 10px;
  text-align: center;
}
.trendingFacebookContainer .post .postNumber{
  font-size: 12px;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  text-transform: capitalize;
  text-align: center;
  color: #636363;
}
.trendingFacebookContainer .post .postImageCompetitor{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  margin: auto;
}
.trendingFacebookContainer .postPlaceholder{
  min-width: 225px;
  height: 100%;
  margin: 0px 5px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  background: #2b2b2b;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 332px;
}