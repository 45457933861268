/*
  Keizyu
*/
.calendarShell {
  margin-top: 80px;
  position: relative;
}

.rbc-btn-group {
  display: none;
}

.rbc-btn-group:first-child {
  display: inline-block;
  position: absolute;
}

.rbc-btn-group:first-child button:first-child {
  display: none;
}

.rbc-btn-group:first-child button:nth-child(3) {
  margin-left: 13rem;
}

.rbc-day-bg {
  border: 1px solid #CF3;
}

.rbc-off-range {
  color: transparent;
}

.rbc-off-range-bg {
  border-color: #848484 !important;
  background: transparent;
}

.rbc-header {
  border: 0 !important;
}

.rbc-month-view {
  border: 0;
}

.rbc-month-row+.rbc-month-row {
  border-top: 0;
}

.rbc-day-bg+.rbc-day-bg {
  border-left: 0;
}

.rbc-today {
  background-color: transparent;
}

.rbc-toolbar {
  margin-top: 45px;
  margin-bottom: 45px;
}

.calendarControls {
  position: absolute;
  right: 18px;
  top: 40px;
}

.calendarPosts {
  padding: 0px !important;
  width: 1034px;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  position: relative;
}
.calendarPostsVision{
  width: 812px;
}
.calendarPosts .bp3-dialog-body{
  margin: 0px;
}
.calendarPostsConfigDate{
  position: relative;
}
.calendarPostsConfigDate input[type=text]{
  border: none;
  background: none;
  box-shadow: none;
  color: #636363;
  opacity: 0;
  height: 0px;
  width: 0px;
}
.calendarPostsConfigDate input[type=text] + label{
  color: #646464;
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 30px;
  cursor: pointer;
}
.calendarPostsConfigDate input[type=text]:disabled,
.calendarPostsConfigDate input[type=text][disabled],
.calendarPostsConfigDate input[type=text]:disabled + label,
.calendarPostsConfigDate input[type=text][disabled] + label{
 cursor: not-allowed;
}
.calendarPosts .bp3-card {
  border: 1px solid #CEFF33;
  background-color: #2A2A2A !important;
}
.calendarPostsConfig .calendarPostsConfigContainer{
  height: 40px;
}
.calendarPostsConfig  .dropdownContainer{
  width: 100%;
}
.calendarPostsConfig .dropdownContainer .dropdown{
  background-color: transparent;
}
.calendarPostsConfig .postExtrasCategory .react-select-container {
  color: #636363;
  font-size: 12px;
  width: 100%;
}

.calendarPostsConfig .postExtrasCategory .react-select-container .react-select__control {
  background-color: transparent;
}

.calendarPostsConfig .searchCalendarIcon {
  width: 100%;
  height: 100%;
  background: #CF3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  color: #636363;
}

.calendarPostsConfig .searchCalendarIcon i {
  font-size: 15px;
}

.calendarPostsConfig .bp3-input {
  padding-right: 10px;
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #636363;
  font-size: 15px;
}

.calendarPostsConfig .bp3-input-group {
  padding: 4px 0;
}

.calendarPostsImages {
  height: 100px;
  padding: 5px;
  display: flex;
}
.providerInfoSlider{
  width: 90px;
  height: 70px;
}
/*.calendarPostsImages .providerInfo {
  width: 90px;
  height: 90px;
  float: left;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 5px 0px;
}*/
.providerInfoSlider .swiper-pagination{
  bottom: 0px;
}
.providerInfoSlider .swiper-pagination-bullet{
  background: #646464;
}
.providerInfoSlider .swiper-pagination-bullet-active{
  background: #CCFF33;
}
.providerInfo{
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #2a2a2a;
}
.calendarPostsImages .providerInfo i {
  font-size: 40px;
  text-align: center;
}
.calendarPostsImages .providerInfo p {
  font-size: 8px;
  line-height: normal;
  width: 100%;
  margin-bottom: 0;
  text-align: center;
}

.calendarPostsImages .fileInput {
  width: calc(100% - 90px);
  height: 90px;
  float: left;
}

.calendarPostsImages .fileInput .bp3-file-input {
  height: 100%;
  width: 100%;
}

.calendarPostsImages .fileInput .bp3-file-upload-input {
  height: 100%;
  border: 1px solid #cf3;
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.calendarPostsImages .fileInput .bp3-file-upload-input::after {
  content: 'T';
  font-family: 'Tinos', serif !important;
  content: 'T';
  height: 100%;
  margin-top: 0px;
  line-height: 4.4;
  background: #CF3;
  color: #363737;
  font-size: 20px;
  border-radius: 0;
  margin-right: 0px;
}
.calendarInputCopy {
  height: 65px;
  width: 100%;
  min-height: 67px;
  border: 1px solid #cf3;
  border-left: none;
  border-right: none;
  position: relative;
}
.calendarInputCopy .calendarCopyTextArea {
  border: none;
  background: transparent;
  color: #cf3;
  resize: none;
  padding: 3px;
  font-size: 10px;
  line-height: 1;
  padding-bottom: 15px;
  box-shadow: none;
  height: 100%;
  width: 100%;
}

.calendarInputCopy .calendarCopywords {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0px 5px;
  font-size: 11px;
  color: #cf3;
  line-height: 0;
  height: 12px;
  margin-bottom: 3px;
}

.calendarPosts .calendarInput {
  width: 100%;
  height: 50px;
  border: 1px solid #cf3;
  border-left: none;
  border-right: none;
  position: relative;
}

.calendarPosts .calendarInput textarea {
  border: none;
  background: transparent;
  color: #cf3;
  resize: none;
  padding: 3px;
  font-size: 10px;
  line-height: 1;
  box-shadow: none;
  height: 100%;
  width: 100%;
}

.calendarComments {
  padding: 0px 8px;
  border-bottom: 1px solid #cf3;
  display: flex;
}
.calendarCommentsComment{
  flex: 1;
}
.calendarCommentsStatus{
  width: 178px;
}
.calendarComments .calendarInput {
  border: 0;
}
.calendarComments .postExtrasCategory .react-select-container {
  color: #636363;
  font-size: 12px;
  width: 100%;
}
.calendarStatus{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-left: 1px solid #cf3;
}
.calendarStatus .dropdownContainer {
  min-width: 100%;
  max-height: 100%;
}
.calendarStatus .dropdownContainer  .dropdown{
  background-color: transparent;
}

.actionsButtons {
  margin-top: 14px;
}

.actionsButtons button {
  background: #ccff33;
  color: #000;
  font-weight: bold;
  text-align: center;
  border: none;
  font-size: 15px;
  padding: 8px;
  border-radius: 4px;
  border: solid rgba(0, 0, 0, 0.2);
  border-width: 1px 1px 5px;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.1), inset 0 0 3px rgba(255, 255, 255, 0.3);
}

.actionsButtons button+button {
  margin-left: 6px;
}
.bestPostContainer{
  width: 100%;
  height: 152px;
  overflow: auto;
  overflow-y: hidden;
}
.bestPostItems{
  display: flex;
}
.bestPostItem {
  width: 143px;
  height: 143px;
  margin: 5px;
  cursor: pointer;
  position: relative;
}

.bestPostItem .bestPostImage {
  display: flex;
  width: 143px;
  height: 143px;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(../../../../assets/img/logo.png);
  background-color: #363737;
}

.bestPostItem .bestPostImageHover {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #cf3;
  font-weight: bold;
  color: #2a2a2a;
  font-size: 15px;
  opacity: 0;
  transition: opacity .5s;
}

.bestPostItem:hover .bestPostImageHover {
  opacity: 1;
}

.bestPostItem img {
  width: 100%;
}


/*
  Miguel
*/

.calendar {
  background-color: #2A2A2A;
}

.calendar [className=rbc-show-more] {
  display: none;
}

.toolbarCalendar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 80px;
}

.toolbarCalendarFilter,
.toolbarCalendarNavigate,
.toolbarCalendarNew {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbarCalendarFilter div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}

.toolbarCalendarFilter div * {
  margin: 0px;
}

.toolbarCalendarTwitter {
  background: #55acee;
  color: #fff;
}

.toolbarCalendarFacebook {
  background: #3750a2;
  color: #fff;
}

.toolbarCalendarInstagram {
  background: #a7328b;
  color: #fff;
}

.toolbarCalendarLinkedin {
  background: #007ab6;
  color: #fff;
}

.toolbarCalendarAll {
  background: #cf3;
  color: #53585f;
  font-size: 10px;
}

.toolbarCalendarBack,
.toolbarCalendarNext {
  border-radius: 50%;
  margin: 0px 10px;
  background-color: #cf3;
  color: #2A2A2A;
  height: 24px;
  width: 24px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.toolbarCalendarLabel {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.toolbarCalendarNew button {
  background: #ccff33;
  color: #000;
  font-weight: bold;
  text-align: center;
  border: none;
  font-size: 11px;
  padding: 7px;
  margin: 3px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.calendarDay {
  border: solid 1px #cf3;
  background-color: transparent;
}

.calendarDayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-left: 4px;
}

.calendarDayHeader p {
  margin: 0;
  font-size: 10px;
}

.dayHeaderEvents p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 2px solid #cf3;
  border-radius: 50%;
}

p.dayHeaderNumber,
p.dayHeaderNumber:hover {
  color: #848484;
  font-weight: 200;
  font-size: 14px;
  opacity: 1;
  text-decoration: none;
}

.calendarDay.rbc-off-range-bg {
  border-color: #848484;
}

.calendar .rbc-month-view {
  border: none;
}

.calendar .rbc-header {
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border: none;
}
.calendarPosts *:disabled,
.calendarPosts *[disabled]{
  cursor: not-allowed;
}

.calendarEvents {
  overflow: auto;
  height: calc(100% - 26px);
  margin-top: 26px;
}

.calendarEvent {
  width: 90%;
  margin: 4px 5%;
  border: none;
  display: flex;
  padding: 2px 4px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}

.calendarEventTitle {
  flex: 1;
}

.calendarEventStatus {
  font-weight: bold;
  margin-right: 4px !important;
}

.calendarEvent-facebook {
  background: #3750a2;
}

.calendarEvent * {
  margin: 0px;
}

.calendarDropZone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #cf3;
  cursor: pointer;
}
.calendarDropZone.disabled{
  cursor: not-allowed;
}
.calendarDropZone input[type=file] {
  width: 100%;
  height: 100%;
  display: block;
}

.calendarDropZone .calendarFilesType {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  float: right;
  color: #2a2a2a;
  background: #cf3;
}

.calendarDropZone .calendarDropZoneContainer {
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendarDropZone .calendarDropZonePlaceholder {
  font-weight: bold;
  color: #fff;
  font-size: 12px;
}

.calendarDropZone .calendarFilesThumbs {
  display: flex;
  align-items: center;
  overflow: auto;
}

.calendarDropZone .calendarFilesThumbs .react-thumbnail-generator {
  width: 65px;
  height: 65px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid #cf3;
}

.calendarDropZone .calendarFilesThumbs .react-thumbnail-generator img {
  width: 100%;
}

.calendarDropZone .calendarFilesThumbs .filesThumbContainer {
  margin: 0px 5px;
  position: relative;
}

.calendarDropZone .calendarFilesThumbs .filesThumbContainer .filesThumbDelete {
  width: 0;
  height: 0;
  border-right: 15px solid #bf372d;
  border-top: 15px solid #bf372d;
  border-left: 15px solid rgba(0, 0, 0, 0);
  border-bottom: 15px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  right: 0;
  top: 0;
  position: absolute;
  margin: 0;
  z-index: 1;
}

.calendarDropZone .calendarFilesThumbs .filesThumbContainer .filesThumbDelete i {
  position: relative;
  bottom: 18px;
  left: 2px;
  font-size: 10px;
}
/*TimeDatePicker*/
.datepicker--pointer{
  background: #2A2A2A;
  border-top: 1px solid #CEFF33;
  border-right: 1px solid #CEFF33;
}
.datepicker--nav{
  border-bottom: 1px solid #CEFF33;
}
.datepicker{
  background: #2A2A2A;
  border: 1px solid #CEFF33;
}
.datepicker--buttons, 
.datepicker--time{
  border: 1px solid #CEFF33;
}
.datepicker--cell.-selected-, 
.datepicker--cell.-selected-.-current-,
.datepicker--cell.-selected-.-focus-,
.datepicker--cell.-focus-,
.datepicker--cell.-current-,
.datepicker--cell.-other-decade-.-focus-{
  background-color: #CEFF33;
  color: #2A2A2A;
}
.datepicker--cell.-current-{
  background-color: #CEFF33;
}
.datepicker--nav-title, 
.datepicker--nav-title i,

.datepicker--nav-action:hover, 
.datepicker--nav-title:hover{
  color: #CEFF33;
  background-color: transparent;
}
.datepicker--cell,
.datepicker--day-name{
  color: #CEFF33;
}
.datepicker--cell-day.-focus-{
  background-color: #CEFF33;
  color: #2A2A2A;
}
.datepicker--time-current-hours, 
.datepicker--time-current-minutes,
.datepicker--time-current-ampm{
  color: #CEFF33;
}
.datepicker--nav-action path{
  stroke: #CEFF33;
}
.datepicker--time-current-hours.-focus-:after, 
.datepicker--time-current-minutes.-focus-:after{
  opacity: 1;
  background-color: transparent;
}
.datepicker--time-current-colon{
 color: #CEFF33;
}
.calendarAccounts{
  display: flex;
  align-items: center;
  padding: 10px;
}
.calendarAccounts input[type=checkbox],
.calendarAccounts input[type=radio]{
  display: none;
}
.calendarAccounts input[type=checkbox]:checked + label i,
.calendarAccounts input[type=radio]:checked + label i{
  display: flex;
}
.calendarAccounts input[type=checkbox]:disabled + .accountSocialNetworkCalendar,
.calendarAccounts input[type=checkbox][disabled] + .accountSocialNetworkCalendar{
  cursor: not-allowed;
}
.accountSocialNetworkCalendar{
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: relative;
  border-width: 2px;
  border-style: solid;
  margin: 4px;
  cursor: pointer;
}
.accountSocialNetworkCalendar i{
  position: absolute;
  top: -8px;
  right: -2px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: none;
  justify-content: center;
  align-items: center;
}
.accountSocialNetworkCalendar[provider='facebook'],
.accountSocialNetworkCalendar[provider='ads'],
.accountSocialNetworkCalendar[provider='facebook'] i,
.accountSocialNetworkCalendar[provider='ads'] i{
  border-color: #405e96;
  background-color: #405e96;
} 
.accountSocialNetworkCalendar[provider='instagram'],
.accountSocialNetworkCalendar[provider='instagram'] i{
  border-color: #a7328b;
  background-color: #a7328b;
} 

.accountSocialNetworkCalendar[provider='linkedin'],
.accountSocialNetworkCalendar[provider='linkedin'] i{
  border-color: #2378bb;
  background-color: #2378bb;
} 

.accountSocialNetworkCalendar[provider='twitter'],
.accountSocialNetworkCalendar[provider='twitter'] i{
  border-color: #51afe9;
  background-color: #51afe9;
} 

.accountSocialNetworkCalendar[provider='google'],
.accountSocialNetworkCalendar[provider='google'] i{
  border-color: #d94e31;
  background-color: #d94e31;
} 
.newCalendarButtons{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.newCalendarButtons button{
  margin: 4px;
}
.newCalendarEdit,
.newCalendarEdit:hover{
  background-color: #A254B5;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border: none;
  font-size: 12px;
  height: 44px;
  min-width: 60px;
  width: auto;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  border: solid rgba(0,0,0,0.2);
  border-width: 1px 1px 5px;
  box-shadow: 0 5px 0 rgba(0,0,0,0.1), inset 0 0 3px rgba(255,255,255,0.3);
}
.newCalendarCreate,
.newCalendarCreate:hover{
  background-color: #ccff33;
  color: #000;
  font-weight: bold;
  text-align: center;
  border: none;
  font-size: 12px;
  height: 44px;
  min-width: 60px;
  width: auto;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  border: solid rgba(0,0,0,0.2);
  border-width: 1px 1px 5px;
  box-shadow: 0 5px 0 rgba(0,0,0,0.1), inset 0 0 3px rgba(255,255,255,0.3);
}
.newCalendarCreate:disabled,
.newCalendarCreate:disabled:hover,
.newCalendarCreate[disabled],
.newCalendarCreate[disabled]:hover{
  background-color: #646464;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border: none;
  font-size: 12px;
  height: 44px;
  padding: 8px;
  border-radius: 4px;
  border: solid rgba(0,0,0,0.2);
  border-width: 1px 1px 5px;
  cursor: not-allowed;
  box-shadow: 0 5px 0 rgba(0,0,0,0.1), inset 0 0 3px rgba(255,255,255,0.3);
}
.newCalendarDelete,
.newCalendarDelete:hover{
  background-color: rgb(191, 55, 45);
  color: #fff;
  font-weight: bold;
  text-align: center;
  border: none;
  font-size: 12px;
  height: 44px;
  width: 60px;
  padding: 8px;
  border-radius: 4px;
  border: solid rgba(0,0,0,0.2);
  border-width: 1px 1px 5px;
  box-shadow: 0 5px 0 rgba(0,0,0,0.1), inset 0 0 3px rgba(255,255,255,0.3);
}
.calendarPosts i.closed{
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 10px;
  font-size: 18px;
  cursor: pointer;
}
.calendarPostsMessages{
  height: 334px;
  overflow: hidden;
  overflow-y: auto;
}
.calendarPostsMessages ul{
  padding-left: 0;
  list-style: none;
  margin: 0;
}
.calendarPostsMessages ul li{
  color: #fff;
  font-size: 12px;
  line-height: normal;
  margin: 5px auto;
  padding: 5px 0 8px;
  width: 100%;
  border-bottom: 1px solid #CF3;
}
#emojiArea .emojionearea-editor{
  min-height: 58px;
  height: 58px;
  color: #cf3;
  padding-left: 0px;
}
#emojiArea .emojionearea-editor:empty:before{
  color: #646464;
}
#emojiArea .emojionearea.emojionearea-disable{
  cursor: not-allowed;
}
#emojiArea .emojionearea.emojionearea-disable:before{
  background-color: transparent;
}
#emojiArea .emojionearea-button>div, 
#emojiArea .emojionearea-picker 
.emojionearea-wrapper:after{
  background: none;
  background-image: none !important;
}
#emojiArea .emojionearea-button-open,
#emojiArea .emojionearea-button-close{
  display: flex;
  justify-content: center;
  align-items: center;
}
#emojiArea .emojionearea-button-open:before{
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: '\f5B8';
  font-size: 17px;
}
#emojiArea .emojionearea-button-close:before{
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: '\F00D';
  font-size: 17px;
}
#emojiArea .emojionearea-search{
  color: #000;
}
.providerInfoTxt{
  margin-bottom: 16px;
}
.newEventContent{
  display: flex;
  width: 1034px;
  position: relative;
  margin: 0px;
}
.calendarPostsVision .newEventLabels{
  width: 812px;
}
.newEventGrid{
  position: relative;
  width: 812px;
}
.newEventLabels{
  height: 578px;
  margin-left: 12px;
  width: 224px;
}
.calendarPostsVision .newEventLabels{
  display: none;
}
.newEventLabels ul{
  margin: 0px;
  padding: 0px;
  overflow: auto;
  height: 100%;
  color: #f5f8fa;
}
.newEventLabels ul li{
  height: calc((100% - 40px) / 20);
}
.newEventLabelsTitle{
  text-align: center;
  border-bottom: 2px solid #CF3;
  height: 40px;
  color: #636363;
  margin-bottom: 10px;
  font-weight: 900;
  font-size: 16px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.newEventLoading{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  z-index: 100;
}