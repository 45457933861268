#socialEngagement{
  width: 100%;
  height: 476px;
}
.socialEngagementContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.socialEngagementContainer > div{
  width: 100%;
  height: 100%;
}
#socialEngagementChart{
  height: 100%;
}
.socialEngagementInfo{
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.socialEngagementInfoTitle{
  font-weight: bold;
  font-size: 16px;
  color: #CF3;
  margin: 5px 0px;
  margin-bottom: 10px;
  text-align: center;
}
.socialEngagementInfoValue{
  font-size: 16px;
  color: #BABABA;
  margin: 0px 20px;
  font-weight: bold;
  text-align: center;
}
#socialEngagement table{
  width: 100%;
}
#socialEngagement table,
#socialEngagement td,
#socialEngagement th{  
  border: .5px solid #4a4a4a;
}
#socialEngagement thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#socialEngagement tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: hidden;
}
#socialEngagement tr{
  display: flex;
  width: 100%;
}
#socialEngagement thead tr th:first-child,
#socialEngagement tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#socialEngagement thead tr th:last-child,
#socialEngagement tbody tr td:last-child{
  text-align: right;
  flex-basis: 66px;
}
#socialEngagement th,
#socialEngagement td{
  padding: 4px;
}