#googleSearchTerm{
  height: 476px;
}
.googleSearchTermContainer{
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
#googleSearchTerm table{
  width: 100%;
}
#googleSearchTerm table,
#googleSearchTerm td,
#googleSearchTerm th{  
  border: .5px solid #4a4a4a;
}
#googleSearchTerm thead {
  color: #CF3;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googleSearchTerm tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#googleSearchTerm tr{
  display: flex;
  width: 100%;
}
#googleSearchTerm thead tr th,
#googleSearchTerm tbody tr td{
  text-align: center;
  flex: 1;
}
#googleSearchTerm thead tr th:first-child,
#googleSearchTerm tbody tr td:first-child{
  text-align: left;
  flex: 2;
}
#googleSearchTerm th,
#googleSearchTerm td{
  padding: 4px;
}