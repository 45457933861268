#googleSiteSpeed{
  height: 476px;
}
.googleSiteSpeedContainer{
  height: calc(100% - 60px);
  width: 100%;
}
.googleSiteSpeedContainer > div{
  height: 100%;
  width: 100%;
}
.googleSiteSpeedItems{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.googleSiteSpeedItem{
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.googleSiteSpeedIcon{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}
.googleSiteSpeedIcon i{
  font-size: 50px;
}
.googleSiteSpeedItem p{
  font-size: 18px;
  margin: 0px;
  text-align: center;
  font-weight: bold;
}