#googleFrequencyQuality{
  height: 476px;
}
.googleFrequencyQualityContainer{
  height: calc(100% - 60px);
  width: 100%;
}
.googleFrequencyQualitySessionsTitle{
  color: #fff;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  margin: 0px;
}
.googleFrequencyQualitySessionsValue{
  font-weight: 700;
  color: #cf3;
  margin: 15px;
  font-size: 25px;
  text-align: center;
  margin: 0px;
}
.googleFrequencyQualityItemsTitles{
  width: calc(100% - 70px);
  color: #fff;
  display: flex;
  margin-left: auto;
  font-size: 12px;
  justify-content: space-between;
}
.googleFrequencyQualityItem{
  display: flex;
}
.googleFrequencyQualityItemChart{
  height: 50px;
  width: 100%;
  display: flex;
  margin: 8px 0px;
}
.googleFrequencyQualityItemRange{
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0px;
  margin-right: 10px;
  height: 50px;
  width: 70px;
  text-align: left;
}
.googleFrequencyQualityItemChart div{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: .5px solid #fff;
}
.googleFrequencyQualityItemChart div:nth-child(1) {
  background: #cf3;
  color: #36383a;
}
.googleFrequencyQualityItemChart div:nth-child(2) {
  background: #4fabe6;
  color: #fff;
}