#sidebarBackDrop{
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: pointer;
  transition: all .5s;
}
body.show-sidebar #sidebarBackDrop{
  display: block;
  background-color: rgba(0, 0, 0, .5);
}
.sidebarShell{
  position: fixed;
  top: 0px;
  right: -300px;
  z-index: -10000;
  background-color: #525454;
  height: 100%;
  transition: all .5s;
}
body.show-sidebar .sidebarShell{
  right: 0px;
}
.sidebarShellContainer{
  padding: 0px 10px;
  overflow: auto;
  height: 100%;
  width: 300px;
  border-left: 2px solid #cf3;
}
.sidebar {
  height: -webkit-calc(100% - 35px);
  height: calc(100% - 35px);
  top: 0px;
  overflow: auto;
  padding-bottom: 10px;
}
.sidebar-avatar {
  width: 56px;
  height: 56px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.sidebar-avatar + img {
  margin-left: 6px;
}
.sidebar-title {
  text-align: center;
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: lighter;
  border-bottom: 2px solid #cf3;
  margin-top: 15px;
  padding: 0px;
  padding-bottom: 10px;
}
.sidebar-title__left {
  text-align: left;
}
.sidebar-accounts, .sidebar-brands {
  text-align: center;
}
.sidebar-navegation__list {
  padding: 0 1rem;
  margin: 1.3rem 0;
}
.sidebar-navegation__list--item {
  color: #CF3;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 11px;
  border-bottom: 1px solid #2a2a2a;
  margin: 3px 0;
  padding: 6px 0 !important;
}
.sidebar-navegation__list--item a {
  color: #CF3;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 11px;
}
.sidebar-navegation__list--item i {
  color: #767676;
  margin-right: 10px;
  width: 14px;
}
.sidebar-categories .bp3-tag {
  background: #2A2A2A;
  color: #b3b3b3;
  font-weight: lighter;
  padding: 5px;
  border-radius: 7px;
  margin: 5px 2.5px;
  border: solid 1px #cf3;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.sidebar-categories .bp3-tag .bp3-icon {
  width: 13px;
}
.sidebar-categories .bp3-tag .bp3-icon path {
  fill: #b3b3b3;
}
.sidebar .bp3-button .bp3-icon path {
  fill: #fff;
}
.sidebar .bp3-button:hover {
  background: none;
}
.sidebar .bp3-button:hover .bp3-icon path {
  fill: #CF3;
}
.sidebar-optimalPosts .bp3-form-group {
  margin-bottom: 6px;
}
.sidebar-optimalPosts .bp3-form-group label {
  width: 5rem;
  color: #767676;
}
.sidebar-optimalPosts .bp3-input-group {
  width: 3.5rem;
}
.sidebar .button {
  font-weight: bold;
  text-align: center;
  font-size: 10px;
  padding: 6px 16px;
  min-height: 25px;
}
.sidebar .button__save {
  margin-left: 5.5rem;
  padding: 6px 18px;
}
.sidebar .button__edit {
  padding-left: 2rem;
  padding-right: 2rem;
}
.sidebar .button + .button {
  margin-left: 10px;
}
.sidebar-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 2px solid #cf3;
  height: 35px;
}
.sidebar-controls__btn {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  border-right: 2px solid #cf3;
  text-align: center;
  height: 35px;
  padding: 9px 0;
}
.sidebar-controls__btn:last-child {
  border-right: 0;
}
.sidebar-controls__btn a {
  color: #fff;
}
.sidebar-controls__btn a:hover {
  text-decoration: none;
}
.bp3-tag .bp3-button {
  padding: 0;
  margin: 0;
  min-height: 13px;
}
.competitorAvatars .competitorImage {
  width: 50px;
}
.competitorAvatars .bp3-file-input {
  width: 100%;
}
.competitorAvatars .bp3-file-upload-input {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  border: 0;
  box-shadow: none;
  display: inline-block;
  margin: 0 auto;
  color: #636363;
  font-size: 27px;
  font-weight: bold;
  line-height: 1.8;
}
.competitorAvatars .bp3-file-upload-input::after {
  content: none;
}

/* MIKE */
.sidebarInstagram .bp3-form-group {
  margin-bottom: 6px;
  flex: 1;
}
.sidebarInstagram .bp3-form-group .bp3-form-content{
  width: 100%;
}
.sidebarInstagram .bp3-form-group label {
  color: #767676;
}
.sidebarInstagram .bp3-input-group {
  flex: 1;
}
.sidebarInstagram input[type=text]{
  border: 1px solid #CF3;
  background: #4f4f4f;
  color: #fff;
  text-align: center;
  font-weight: lighter;
}
.sidebarInstagram input[type=text]::placeholder{
  color: #2A2A2A;
}
.sidebarInstagram .button{
  margin-left: 4px;
  height: 30px;
}
.sidebarInstagram .sidebarInstagramForm{
  display: flex;
}