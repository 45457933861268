.googleConversionContainer{
	width: 100%;
	height: 158px;
}
.googleConversionContainerItems{
	display: flex;
	justify-content: space-around;
	height: 100%;
	width: 100%;
	align-items: center;
}
.googleConversionItem{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.googleConversionIcon{
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.googleConversionIcon i{
	font-size: 50px;
}
.googleConversionInfo p{
	color: #fff;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin: 0px;
}