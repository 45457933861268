.competitorsSettings {
  background: #363737;
  padding: 10px;
  width: 450px;
  border-radius: 5px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  height: 300px;
}
.competitorsContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.competitorsHeader {
  padding-bottom: 10px;
}
.competitorsHeaderTitle {
  color: #ccff33;
  font-size: 14px;
  font-weight: bold;
}
.competitorsHeaderSearch {
  display: flex;
}
.competitorsHeaderSearch input {
  flex: 1;
  border: 1px solid #363737;
  background: #7b7b7b;
  padding: 0 5px;
  color: #ccff33;
  height: 30px;
  width: 80%;
  font-size: 12px;
}
.competitorsHeaderButtom {
  background-color: #ccff33;
  color: #000;
  font-weight: bold;
  text-align: center;
  border: none;
  font-size: 11px;
  margin: 3px;
  border-radius: 4px;
  cursor: pointer;
  padding: 7px;
}
.competitorsBody {
  flex: 1;
  overflow: auto;
}
.competitorsBody .competitorsBodyNot{
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 20px;
  width: 100%;
  height: 88%;
}
.competitorListItemSwitch {
  font-size: 13px;
  color: #7b7b7b;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.competitorListItemSwitch input:checked~span {
  background-color: #ccff33 !important;
}
.competitorListItemLabel{
  display: flex;
  align-items: center;
}
.competitorListItemLabel div{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.competitorsFotter {
  display: flex;
  justify-content: center;
}
.competitorsFotterSave {
  background-color: #ccff33;
  color: #000;
  font-weight: bold;
  text-align: center;
  border: none;
  font-size: 11px;
  margin: 3px;
  border-radius: 4px;
  cursor: pointer;
  width: 50px;
  padding: 8px;
}