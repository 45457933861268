
.googleSalesScoreLists{
  width: 400px;
  padding: 10px 0px;
  height: 100%;
  position: relative;
}
.googleSalesScoreListsContainer{
  background: #2b2b2b;
  width: 100%;
  height: 364px;
  padding: 20px 0px;
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: center;
}
.googleSalesScoreListsTitle{
  font-size: 14px;
  color: #cf3;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
}
.googleSalesScoreListsContainer ul{
  padding: 0px;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.googleSalesScoreListsContainer ul li{
  text-align: left;
  color: #cf3;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 40px);
  margin: 4px auto;
}