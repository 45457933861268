#socialDemografics{
  height: 476px;
}
.socialDemograficsContainer{
  height: calc(100% - 60px);
}
#socialDemografics .demographicsSubtitle{
  display: flex;
  justify-content: center;
}
#socialDemografics .demographicsSubtitleText{
  font-weight: 900;
  font-size: 17px;
  color: #636363;
  margin-bottom: 10px;
  text-align: center;
}
#socialDemografics .demographicsSubtitleValue{
  color: #bae831;
  font-size: 35px;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;
}
#socialDemografics .demographicsFlags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 152px;
  max-width: 350px;
  margin: auto;
}
#socialDemografics .demographicsFlagsImage {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 78px;
  height: 52px;
  margin: 4px;
  cursor: pointer;
}
#socialDemografics .demographicsCities {
  margin-top: 26px
}
#socialDemografics .demographicsCities table{
  width: 100%;
}
#socialDemografics table,
#socialDemografics td,
#socialDemografics th{  
  border: .5px solid #4a4a4a;
}
#socialDemografics thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#socialDemografics tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: hidden;
}
#socialDemografics tr{
  display: flex;
  width: 100%;
}
#socialDemografics thead tr th:first-child,
#socialDemografics tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#socialDemografics thead tr th:last-child,
#socialDemografics tbody tr td:last-child{
  text-align: right;
  width: 52px;
}
#socialDemografics th,
#socialDemografics td{
  padding: 4px;
}