#socialReactions{
  height: calc((476px / 2) - 5px);
}
.socialReactionsContainer{
	width: 100%;
	height: calc(100% - 60px);	
}
.socialReactionsContainer > div{
	width: 100%;
	height: 100%;
}
.socialReactionsContainer .socialReactionsChart{
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: row;
	overflow: hidden;
}
.socialReactionsPositive,
.socialReactionsNegative{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: .5px solid #fff;
	height: 130px;
	overflow: hidden;
}
.socialReactionsPositive i,
.socialReactionsNegative i{
	font-size: 40px;
}
.socialReactionsPositive p,
.socialReactionsNegative p{
	margin-bottom: 0px;
	margin-top: 10px;
}

.socialReactionsPositive{
	background-color: #CCFF33;
	color: #36383a;
}
.socialReactionsNegative{
	background-color: #E2474F;
}