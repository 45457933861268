#socialBestHours{
  height: 476px;
}
.socialBestHoursContainer{
  height: calc(100% - 60px);
}
#socialBestHours table{
  width: 100%;
}
#socialBestHours table,
#socialBestHours td,
#socialBestHours th{  
  border: .5px solid #4a4a4a;
}
#socialBestHours thead {
  color: #CF3;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#socialBestHours tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
  max-height: 155px;
}
#socialBestHours tr{
  display: flex;
  width: 100%;
}
#socialBestHours thead tr th,
#socialBestHours tbody tr td{
  text-align: center;
  flex: 1;
}
#socialBestHours thead tr th:first-child,
#socialBestHours tbody tr td:first-child{
  text-align: center;
  font-weight: bold;
  color: #CF3;
  flex-basis: 50px;
}
#socialBestHours th,
#socialBestHours td{
  padding: 4px 0px;
}