#trendingConversations{
	height: calc((476px / 2) - 5px);
  width: 100%;
}
.trendingConversationsContainer{
	width: 100%;
	height: calc(100% - 70px);
}
.trendingConversationsContainer > div{
  width: 100%;
  height: 100%;
}
.trendingConversationsContainer ul{
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.trendingConversationsContainer li{
	flex: 1;
}
.trendingConversationsContainer li a{
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  text-align: left;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}