/*.timeZone .menu{
  max-height: 200px;
  overflow: auto;
  background-color: #2a2a2a !important;
}
.timeZone .menu > .item{
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff !important;
}
.timeZone .menu > .item:hover,
.timeZone .menu > .item.selected{
  background-color: #CCFF33 !important;
  background-image: none !important;
  color: #2a2a2a !important;
}*/
.timeZone .bp3-menu{
  max-height: 200px;
  overflow: auto;
  background-color: #2a2a2a;
  padding: 0px;
}
.timeZone .bp3-menu li{
  width: 100%;
}
.timeZone .bp3-menu a{
  color: #fff;
}
.timeZone .bp3-menu a:hover,
.timeZone .bp3-menu a.bp3-active{
  background-color: #CCFF33;
  color: #2a2a2a;
}
.timeZone .bp3-menu a:hover .bp3-menu-item-label,
.timeZone .bp3-menu a.bp3-active .bp3-menu-item-label{
   color: #2a2a2a;
}
.timeZone .bp3-input-group svg{
  fill: #636363;
}
.timeZone .bp3-input,
.timeZone .bp3-input::placeholder{
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  background: #363737;
  color: #636363;
}