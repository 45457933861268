.calendarDropZone .calendarFilesThumbs .filesThumbContainer .calendarFilesThumb {
  width: 65px;
  height: 65px;
  border: 1px solid #cf3;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendarDropZone .calendarFilesThumbs .filesThumbContainer .calendarFilesThumb video{
  width: 100%;
}