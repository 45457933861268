.dropdownContainer{
  height: 100%;
  max-height: 34px;
  min-width: 200px;
}
.dropdownContainer [role=listbox].dropdown{
  height: 100%;
	width: 100%;
  background: #2a2a2a;
  padding: 8px 6px;
  margin: 0px;
}
.dropdownContainer .dropdown.icon{
	background: transparent;
}
.dropdownContainer [role=listbox].dropdown.disabled{
	cursor: not-allowed !important;
	pointer-events: auto !important; 
}
.dropdownContainer .button,
.dropdownContainer .button:hover,
.dropdownContainer .button:focus{
	width: 100% !important;
	height: 100% !important;
	font-size: 14px !important;
	background: transparent !important;
	color: #ceff33 !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	text-shadow: none !important;
	box-shadow: none !important;
	border: none !important;
	background-image: none !important;
}
.dropdownContainer .dropdown .text{
	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownContainer .ui.dropdown .menu>.item.active, 
.dropdownContainer .ui.dropdown .menu>.item.active:focus, 
.dropdownContainer .ui.dropdown .menu>.item.active:hover,
.dropdownContainer .ui.dropdown .menu>.item:focus, 
.dropdownContainer .ui.dropdown .menu>.item:hover {
  background-color: #CCFF33 !important;
  background-image: none !important;
  color: #2a2a2a !important;
}
.dropdownContainer .ui.dropdown .menu > div div:last-child{
	box-shadow: none;
	position: static;
}
.dropdownContainer div.menu{
	max-height: 110px;
	overflow: auto;
	width: 100%;
	background-color: #2a2a2a !important;
	width: calc(100% + 30px);
	height: auto;
}
.dropdownContainer div.menu .postCategoriesMenu .postCategoryOption[data-active=true]{
	background-color: #CCFF33 !important;
  background-image: none !important;
  color: #2a2a2a !important;
}
.dropdownContainer .ui.dropdown .menu>.item{
	color: #fff !important;
	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px !important;
}
.dropdownContainer .dropdownTitle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;
}