#adsScore{
  width: 100%;
  height: 476px;
  overflow: hidden;
}
.adsScoreContainer{
  width: 100%;
  height: calc(100% - 68px);
}
.adsScoreContainer > div{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}