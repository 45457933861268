.salesSidebar{
  width: 70px;
  position: fixed;
  top: 80px;
  left: 0px;
  background: #CF3;
  height: 100%;
  min-height: 600px;
  box-shadow: 10px 0px 22px -8px rgba(0, 0, 0, 0.66);
  z-index: 10;	
}
.salesSidebar div:nth-child(2){
  border-top-color: #36383A;
  border-top-style: dashed;
  border-top-width: 1px;
}

.salesSidebar div a{
  text-decoration: none;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #36383A;
  cursor: pointer;
  margin-bottom: 0px;
  position: relative;
}
.salesSidebar div.salesSidebarProviders a{
  height: 40px;
}
.salesSidebarProviderImage{
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #36383A;
  border-radius: 50%;
  background-color: #fff;
  border-bottom-style: solid !important;
}
.salesSidebarLabel{
  position: absolute;
  height: 100%;
  left: 0px;
  z-index: -1;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  background-color: #CF3;
  color: #36383A;
  font-size: 16px;
  opacity: 0;
  transition: all .5s;
  white-space: nowrap;
}
.salesSidebar div a i{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #CF3;
}
.salesSidebar div a.active,
.salesSidebar div a:hover{
  background-color: #36383A;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
}
.salesSidebar div.salesSidebarProviders a.active .salesSidebarProviderImage,
.salesSidebar div.salesSidebarProviders a:hover .salesSidebarProviderImage{
  border: 1px solid #CF3;
}
.salesSidebar div a:hover i,
.salesSidebar div a.active i{
  background-color: #36383A;
  color: #fff;
}
.salesSidebar div a:hover .salesSidebarLabel{
  left: 70px;
  opacity: 1;
}