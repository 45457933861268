.listPages{
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  background: #2A2A2A;
  color: #fff;
  border: 1px solid #CCFF33;
  border-radius: 3px;
  width: 450px;
  padding: 10px;
  z-index: 100000;
  height: 80vh; 
}
.listPagesHeader{
  background-color: transparent;
  border-radius: 0px;
}
.listPagesHeader .bp3-heading{
  color: #fff;
  text-transform: capitalize;
}
.listPagesProvider{
 margin-right: 10px;
}
.listPagesHeader .fa-times{
  cursor: pointer;
}
.listPagesContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bp3-dialog-footer .listPagesSelect,
.bp3-dialog-footer .listPagesSelect:hover,
.bp3-dialog-footer .listPagesSelect:disabled{
  background: #CCFF33;
  box-shadow: none;
}
.listPagesContainer .bp3-dialog-body{
  display: flex;
  overflow: auto;
}
.listPagesItems{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.listPagesItem div,
.listPagesItemActive div{
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 100px;
}
.listPagesItemPlaceholder{
  width: 100px;
  height: 100px;
  background-color: transparent;
}
.listPagesItemActive div{
  border: 1px solid #CCFF33;
}
.listPagesItem p,
.listPagesItemActive p{
  margin-top: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
  white-space: nowrap;
}
.listPagesLoading{
  width: 100%;
  height: 100%;
  align-self: center;
}
.listPagesItemsError {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}