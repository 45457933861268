.ReloadChart{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column !important;
	width: 100%;
	height: 100%;
}
.ReloadChart p{
  color: #636363;
  font-weight: 900;
  font-size: 16px;
}
.ReloadChart .fa-sad-cry{
	font-size: 30px;
	color: #636363;
}
.ReloadChart .fa-redo-alt{
	font-size: 30px;
	color: #636363;
	cursor: pointer;
}