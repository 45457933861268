.sidebarOptimalPosts input[type=text]{
  border: 1px solid #CF3;
  background: #4f4f4f;
  color: #fff;
  text-align: center;
  font-weight: lighter;
  width: 96px;
}
.sidebarOptimalPosts input[type=text]::placeholder{
  color: #2A2A2A;
}
.sidebarOptimalPosts label{
	width: 80px;
	color: #636363;
	text-align: left;
	margin-top: 4px;
}
.sidebarOptimalPostsSave{
  margin: auto !important;
  display: block;
}