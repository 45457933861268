#socialPerfectMix{
  height: 476px;
}
.socialPerfectMixContainer{
  height: calc(100% - 60px);
}
#socialPerfectMixChart{
  height: 100%;
}
#socialPerfectMix table{
  width: 100%;
}
#socialPerfectMix table,
#socialPerfectMix td,
#socialPerfectMix th{  
  border: .5px solid #4a4a4a;
}
#socialPerfectMix thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#socialPerfectMix tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: hidden;
}
#socialPerfectMix tr{
  display: flex;
  width: 100%;
}
#socialPerfectMix thead tr th,
#socialPerfectMix tbody tr td{
  text-align: center;
  flex: 1;
}
#socialPerfectMix thead tr th:first-child,
#socialPerfectMix tbody tr td:first-child{
  text-align: left;
}
#socialPerfectMix th,
#socialPerfectMix td{
  padding: 4px;
}