.categoryExclude {
  font-size: 13px;
  color: #7b7b7b;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}
.categoryExclude input:checked~span {
  background-color: #ccff33 !important;
}

.categoryExclude .bp3-control-indicator::before{
	background-color: #ffffff !important;
}