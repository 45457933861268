#googlePagePath{
  height: 476px;
}
.googlePagePathContainer{
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
#googlePagePath table{
  width: 100%;
}
#googlePagePath table,
#googlePagePath td,
#googlePagePath th{  
  border: .5px solid #4a4a4a;
}
#googlePagePath thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googlePagePath tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#googlePagePath tr{
  display: flex;
  width: 100%;
}
#googlePagePath thead tr th:first-child,
#googlePagePath tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#googlePagePath thead tr th:last-child,
#googlePagePath tbody tr td:last-child{
  text-align: right;
  flex-basis: 84px;
}
#googlePagePath th,
#googlePagePath td{
  padding: 4px;
}