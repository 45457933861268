#socialColors{
  height: 476px;
}
.socialColorsContainer{
  height: calc(100% - 60px);
}
#socialColorsChart{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  height: 264px;
  padding: 0px;
  position: relative;
}
#socialColorsChart li {
  height: 100%;
  cursor: pointer;
}
#socialColorsChart div{
  position: absolute;
  width: 100%;
  background-color: #363737;
  bottom: 10px;
  left: 0px;
  padding: 6px;
  font-size: 0px 16px;
  height: 32px;
  font-weight: bold;
  color: #636363;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  opacity: 0;
  transition: opacity .5s;
}
#socialColorsChart div p{
  margin: 0px;
}
#socialColorsChart div p:first-child{
  color: #fff;
}
#socialColorsChart div p:last-child{
  color: #cf3;
}
#socialColorsChart li:hover div{
  opacity: 1;
}

#socialColors table{
  width: 100%;
}
#socialColors table,
#socialColors td,
#socialColors th{  
  border: .5px solid #4a4a4a;
}
#socialColors thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#socialColors tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: hidden;
}
#socialColors tr{
  display: flex;
  width: 100%;
}
#socialColors thead tr th,
#socialColors tbody tr td{
  text-align: center;
  flex: 1;
}
#socialColors thead tr th:first-child,
#socialColors tbody tr td:first-child{
  text-align: left;
}
#socialColors th,
#socialColors td{
  padding: 4px;
}
.socialColorstableColor{
  width: 10px;
  height: 10px;
  float: right;
}