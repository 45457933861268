#googleRegion{
  height: 476px;
}
.googleRegionContainer{
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
#googleRegion table{
  width: 100%;
}
#googleRegion table,
#googleRegion td,
#googleRegion th{  
  border: .5px solid #4a4a4a;
}
#googleRegion thead {
  color: #CF3;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googleRegion tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#googleRegion tr{
  display: flex;
  width: 100%;
}
#googleRegion thead tr th,
#googleRegion tbody tr td{
  text-align: center;
  flex: 1;
}
#googleRegion thead tr th:first-child,
#googleRegion tbody tr td:first-child{
  text-align: left;
  flex: 2;
}
#googleRegion th,
#googleRegion td{
  padding: 4px;
}