.webSidebar{
  width: 70px;
  position: fixed;
  top: 80px;
  left: 0px;
  background: #CF3;
  height: 100%;
  min-height: 600px;
  box-shadow: 10px 0px 22px -8px rgba(0, 0, 0, 0.66);
  z-index: 10;  
}
.webSidebar a{
  text-decoration: none;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #36383A;
  cursor: pointer;
  margin-bottom: 0px;
  position: relative;
}
.webSidebarLabel{
  position: absolute;
  height: 100%;
  left: 0px;
  z-index: -1;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  background-color: #CF3;
  color: #36383A;
  font-size: 16px;
  opacity: 0;
  transition: all .5s;
}
.webSidebar a:nth-child(1) .webSidebarLabel{
  width: 90px;
}
.webSidebar a:nth-child(2) .webSidebarLabel{
  width: 110px;
}
.webSidebar a:nth-child(3) .webSidebarLabel{
  width: 70px;
}
.webSidebar a i{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #CF3;
}
.webSidebar a.active,
.webSidebar a:hover{
  background-color: #36383A;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
}
.webSidebar a:hover i,
.webSidebar a.active i{
  background-color: #36383A;
  color: #fff;
}
.webSidebar a:hover .webSidebarLabel{
  left: 70px;
  opacity: 1;
}