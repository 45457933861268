#adsTotalSpend{
	height: calc((476px / 2) - 5px);
	width: 100%;
}
.adsTotalSpendContainer{
	width: 100%;
	height: calc(100% - 60px);
}
.adsTotalSpendContainer > div{
	width: 100%;
	height: 100%;
}
.adsTotalSpend{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #ffffff;
  font-weight: bold;
}
.adsTotalSpend span{
	font-size: 24px;
}
.adsTotalSpend p{
	font-size: 42px;
  margin: 5px;
}