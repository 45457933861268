#googleSalesScore{
  width: 100%;
  height: 476px;
  overflow: hidden;
}
.googleSalesScoreContainer{
  width: 100%;
  height: calc(100% - 68px);
}
.googleSalesScoreContainer > div{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.googleSalesScoreChannels{
  width: calc(100% / 3);
  height: 100%;
  position: relative;
}
.googleSalesScoreChannelsPrev{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 10px;
  z-index: 1;
}
.googleSalesScoreChannelsNext{
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 30px;
  z-index: 1;
}
.googleSalesScoreChannelsPrev i,
.googleSalesScoreChannelsNext i{
  cursor: pointer;
  color: #ccff33;
  font-size: 24px;
}
.googleSalesScoreChannelsContainer{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 328px;
  margin: 30px 0px;
  background: #363737;
  justify-content: space-between;
  align-items: center;
}
.googleSalesScoreChannel{
  background: #2b2b2b;
  width: calc(45% - 8px);
  height: calc(45% - 8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 4px;
}
.googleSalesScoreChannel.active{
  background: #ccff33;
}
.googleSalesScoreChannel.active p{
  color: #2b2b2b;
}
.googleSalesScoreChannelPlaceholder{
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
.googleSalesScoreChannelName{
  color: #ccff33;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
.googleSalesScoreChannelScore{
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  margin: 10px 0px;
  text-align: center;
}
.googleSalesScoreChannelPosition{
  color: #ccff33;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.googleSalesScoreData{
  width: calc(100% / 3);
  height: 100%;
}
.googleSalesScoreDataContainer{
  width: 100%;
  height: calc(100% - 20px);
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
}
.googleSalesScoreDataItem{
  width:  calc(50% - 8px);
  height: calc((100% / 3) - 8px);
  background: #2b2b2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px;
}
.googleSalesScoreDataItem p {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  margin: 0px;
  text-align: center;
}
p.googleSalesScoreTitle {
  font-size: 14px;
  font-weight: 500;
  color: #ccff33;
}