#adsPlacement{
  height: calc((476px / 2) - 5px);
  width: 100%;
}
.adsPlacementContainer{
  width: 100%;
  height: calc(100% - 60px);
}
.adsPlacementContainer > div{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.adsPlacementItem{
  width: calc(50% - 20px);
  height: calc(50% - 20px);
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.adsPlacementIcon{
  width: 70px;
  height: 70px;
  background-color: #2b2b2b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 26px;
}
.adsPlacementValue{
  font-size: 22px;
  margin-left: 12px;
  color: #cf3;
  font-weight: bold;
}
.adsPlacementfacebook {
  background-color: #405e96;
}
.adsPlacementinstagram{
  background-color: #a7328b;
}
.adsPlacementaudience_network{
  background-color: #f79a3a;
}
.adsPlacementmessenger{
  background-color: #405e96;
}