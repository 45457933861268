#socialLabels{
	height: 476px;
  width: 100%;
}
.socialLabelsContainer {
  width: 100%;
  height: calc(100% - 60px);
}
.socialLabelsContainer > div{
  height: 100%;
  width: 100%;
}
.socialLabelsItems{
  height: 100%;
  width: 100%;
  display: flex;
}
.socialLabelsItem {
  flex: 1;
  padding: 10px;
  margin: 4px;
  background: #2A2A2A;
}
.socialLabelsItem table{
	width: 100%;
	height: 100%;
  display: flex;
}

#socialLabels td,
#socialLabels th{  
  border: .5px solid #4a4a4a;
}
#socialLabels thead {
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
#socialLabels tbody{
	color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  width: 100%;
  display: block;
}
#socialLabels tbody tr,
#socialLabels thead tr{
  height: 24px;
  display: flex;
}
#socialLabels tbody tr td:first-child{
  color: #CF3;
  font-weight: bold;
}
#socialLabels tbody tr td:last-child{
  font-weight: bold;
}
#socialLabels thead tr th:first-child,
#socialLabels tbody tr td:first-child{
	text-align: left;
  width: 70%;
}
#socialLabels thead tr th:last-child,
#socialLabels tbody tr td:last-child{
	text-align: right;
  width: 30%;
}
#socialLabels th,
#socialLabels td{
	padding: 4px;
}