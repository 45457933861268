.brandsContainer{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.accountBrand{
	width: 56px;
	height: 56px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 50%;
	position: relative;
	border-width: 2px;
	border-style: solid;
	margin: 4px;
	border-color: #cf3;
	cursor: pointer;
}