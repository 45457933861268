/*
  Keizyu
*/

.calendarShell {
  margin-top: 80px;
  position: relative;
}

/*
  Miguel
*/

.calendar {
  background-color: #2A2A2A;
}

.calendar [className=rbc-show-more] {
  display: none;
}

.toolbarCalendar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 80px;
}

.toolbarCalendarFilter,
.toolbarCalendarNavigate,
.toolbarCalendarNew {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbarCalendarFilter div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}

.toolbarCalendarFilter div * {
  margin: 0px;
}

.toolbarCalendarTwitter {
  background: #55acee;
  color: #fff;
}

.toolbarCalendarFacebook {
  background: #3750a2;
  color: #fff;
}

.toolbarCalendarInstagram {
  background: #a7328b;
  color: #fff;
}

.toolbarCalendarLinkedin {
  background: #007ab6;
  color: #fff;
}

.toolbarCalendarAll {
  background: #cf3;
  color: #53585f;
  font-size: 10px;
}

.toolbarCalendarBack,
.toolbarCalendarNext {
  border-radius: 50%;
  margin: 0px 10px;
  background-color: #cf3;
  color: #2A2A2A;
  height: 24px;
  width: 24px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.toolbarCalendarBack i,
.toolbarCalendarNext i{
  font-size: 10px;
}
.toolbarCalendarLabel {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.toolbarCalendarNew button {
  background: #ccff33;
  color: #000;
  font-weight: bold;
  text-align: center;
  border: none;
  font-size: 11px;
  padding: 7px;
  margin: 3px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.calendarDay {
  border: solid 1px #cf3;
  background-color: transparent;
}

.calendarDayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-left: 4px;
}

.calendarDayHeader p {
  margin: 0;
  font-size: 10px;
}

.dayHeaderEvents p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 2px solid #cf3;
  border-radius: 50%;
}

p.dayHeaderNumber,
p.dayHeaderNumber:hover {
  color: #848484;
  font-weight: 200;
  font-size: 14px;
  opacity: 1;
  text-decoration: none;
}

.calendarDay.rbc-off-range-bg {
  border-color: #848484;
}

.calendar .rbc-month-view {
  border: none;
}

.calendar .rbc-header {
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border: none;
}

.calendarEvents {
  overflow: auto;
  height: calc(100% - 26px);
  margin-top: 26px;
}

.calendarEvent {
  width: 90%;
  margin: 4px 5%;
  border: none;
  display: flex;
  padding: 2px 4px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}

.calendarEventTitle {
  flex: 1;
}

.calendarEventStatus {
  font-weight: bold;
  margin-right: 4px !important;
}
.calendarEventStatus.NC{
  color: #2A2A2A;
} 
.calendarEventStatus.MA{
  color: #E2474F;
}
.calendarEventStatus.WIC{
  color: #F79A3A;
}
.calendarEventStatus.ABC{
  color: #CCFF33;
}
.calendarEventStatus.SP{
  color: #A254B5;
}

.calendarEvent-facebook {
  background-color: #3750a2;
}
.calendarEvent-twitter {
  background-color: #51afe9;
}
.calendarEvent-instagram {
  background-color: #a7328b;
}
.calendarEvent-linkedin {
  background-color: #2378bb;
}

.calendarEvent-error .calendarEventStatus{
  color: #ffffff !important;
}
.calendarEvent-error{
  background-color: #FF9300 !important;
}

.calendarEvent * {
  margin: 0px;
}
.rbc-off-range-bg .calendarEvents{
  display: none;
}