.mainLogin {
  background-color: gray;
  background: url(../../assets/img/loginback.jpg) no-repeat center center;
  background-size: cover;
  height: 100vh;
  color: #fff;
  text-align: center;
}
 .mainLoginLogo {
  text-align: center;
}
 .mainLogin .powered {
  margin-top: 10px;
  font-size: 12px;
}
 .loginButton {
  border: 2px solid #fff;
  border-radius: 4px;
  background-color: transparent;
  padding: 6px;
  color: #fff;
  cursor: pointer;
}
.loginButton > div{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 .loginButtonText {
  margin: 0 10px;
}
 .login {
  overflow: hidden;
}
 

 /* Facebook Login */
.loginButtonFacebook{
  height: 40px;
  width: 180px;
  background-color: #4267b2;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  padding: 0px;
}
.loginButtonFacebook > div{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loginButtonFacebook i{
  font-size: 24px;
  cursor: pointer;
  margin: 8px;
}
.loginButtonFacebook .text{
  text-align: center;
  font-size: 16px;
  margin: 8px;
}