#googleSpecificAudience{
  height: 476px;
}
.googleSpecificAudienceContainer{
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
#googleSpecificAudience table{
  width: 100%;
}
#googleSpecificAudience table,
#googleSpecificAudience td,
#googleSpecificAudience th{  
  border: .5px solid #4a4a4a;
}
#googleSpecificAudience thead {
  color: #CF3;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googleSpecificAudience tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#googleSpecificAudience tr{
  display: flex;
  width: 100%;
}
#googleSpecificAudience thead tr th:first-child,
#googleSpecificAudience tbody tr td:first-child{
  text-align: left;
  flex: 1;
}
#googleSpecificAudience thead tr th:last-child,
#googleSpecificAudience tbody tr td:last-child{
  text-align: right;
  flex-basis: 66px;
}
#googleSpecificAudience th,
#googleSpecificAudience td{
  padding: 4px;
}