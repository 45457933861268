#adsTopInterests{
	height: 476px;
  width: 100%;
}
.adsTopInterestsContainer {
  width: 100%;
  height: calc(100% - 60px);
}
.adsTopInterestsContainer > div{
  height: 100%;
  width: 100%;
}
.adsTopInterestsItems{
  height: 100%;
  width: 100%;
  display: flex;
}
.adsTopInterestsItem {
  flex: 1;
  padding: 10px;
  margin: 4px;
  background: #2A2A2A;
}
.adsTopInterestsItem table{
	width: 100%;
	height: 100%;
  display: flex;
}

#adsTopInterests td,
#adsTopInterests th{  
  border: .5px solid #4a4a4a;
}
#adsTopInterests thead {
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
#adsTopInterests tbody{
	color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  width: 100%;
  display: block;
}
#adsTopInterests tbody tr,
#adsTopInterests thead tr{
  height: 24px;
  display: flex;
}
#adsTopInterests tbody tr td:first-child{
  color: #CF3;
  font-weight: bold;
}
#adsTopInterests tbody tr td:last-child{
  font-weight: bold;
}
#adsTopInterests thead tr th:first-child,
#adsTopInterests tbody tr td:first-child{
	text-align: left;
  width: 70%;
}
#adsTopInterests thead tr th:last-child,
#adsTopInterests tbody tr td:last-child{
	text-align: right;
  width: 30%;
}
#adsTopInterests th,
#adsTopInterests td{
	padding: 4px;
}