.postContainer {
  position: relative;
  width: 368px;
  height: 410px;
  display: inline-block;
  margin: 5px;
  background: #363737;
  padding: 10px;
}
.postIcon {
  width: 0;
  height: 0;
  border-right: 15px solid #ababab;
  border-top: 15px solid #ababab;
  border-left: 15px solid rgba(0, 0, 0, 0);
  border-bottom: 15px solid rgba(0, 0, 0, 0);
  right: 0;
  top: 0;
  position: absolute !important;
  margin: 0 !important;
}
.postIcon i {
  color: #2a2a2a;
  position: relative;
  bottom: 15px;
  left: 0px;
  font-size: 14px;
}
.postImage {
  width: 226px;
  height: 226px;
  vertical-align: top;
  overflow: hidden;
  background: url(../../../assets/img/logo.png) no-repeat center center;
  background-color: #2A2A2A;
  background-size: 20px 20px;
  display: flex;
  float: left;
  align-items: center;
}
.postImage a{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.postImage img {
  width: 100%;
}
.postExtras {
  width: calc(100% - 226px);
  padding: 0;
  display: flex;
  height: 226px;
  float: right;
  text-align: center;
  padding-left: 22px;
  justify-content: space-around;
  flex-direction: column;
}
.postExtrasType{
  color: #ababab;
  font-size: 14px;
}
.postExtrasEngagment {
  margin-bottom: 14px;
}
.postExtrasEngagment h6 {
  font-size: 13px;
  font-weight: bold;
  color: #CF3;
  margin-bottom: 0;
}
.postExtrasEngagment p {
  font-weight: lighter;
  font-size: 16px;
  color: #BABABA;
  margin-top: 8px;
}
.postExtrasCategory .dropdownContainer div.menu{
  max-height: 240px;
  width: calc(100% + 60px);
}
.postExtrasCategory .dropdownContainer{
  background-color: #2a2a2a !important;
  min-width: 100%;
  width: 100px;
}
.postExtrasPromotions{
  margin-top: 8px;
}
.postInsightsInfo {
  display: inline-block;
  float: left;
  width: 50%;
  margin: 5px 0px 0;
}
.postInsightsInfo .postDataItem {
  width: -webkit-calc(33.333%);
  width: calc(33.333%);
  display: inline-block;
  float: left;
  padding: 0 2.5px;
}
.postInsightsInfo h5 {
  font-weight: bold;
  color: #CF3 !important;
  text-transform: uppercase;
  margin-bottom: 0;
}
.postInsightsInfo:nth-child(2n) {
  text-align: right;
}
.postInsightsInfo:nth-child(2n) .postInsightsData {
  border-left: 1px solid #BABABA;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.postDataInfo {
  font-weight: lighter;
  font-size: 16px;
  color: #BABABA;
  margin-bottom: 0;
}
.postDataTitle {
  font-weight: lighter;
  font-size: 10px !important;
  color: #636363;
  margin-bottom: 0;
}
.compertitorPostDate {
  text-align: right;
  font-weight: bold;
  color: #636363;
  margin-bottom: 10px;
  position: relative;
      display: flex;
      align-content: center;
}
.compertitorPostDate .logoSmartPost {
  width: 20px;
  position: absolute;
  left: 0;
}
.compertitorPostDate .logoBrand{
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  border-radius: 50%;
}
.postMessage {
  height: 58px;
  font-weight: bold;
  font-size: 14px;
  color: #636363;
  text-align: left;
  margin: 4px 0px;
  padding: 0;
}
.postMessageParrafo {
  font-weight: bold;
  font-size: 13px;
  padding-top: 10px;
  color: #636363;
  text-align: left;
  width: 100%;
  margin: 10px 0px;
  padding: 0;
}
.postMessageHashtag {
  color: #CF3 !important;
}
.postTopContainer{
  display: inline-block;
  width: 100%;
}

.postCategoriesMenuContainer .menu{
  height: 216px;
  width: 174px;
}
.postCategoriesMenuControl{
  display: none;
}
.postCategoriesMenu{
  overflow: auto;
  width: 100%;
  background-color: #2a2a2a;
}
.postCategoryOption{
  cursor: pointer;
  border: none;
  height: auto;
  text-align: left;
  font-size: 1rem;
  text-transform: none;
  font-weight: 400;
  box-shadow: none;
  touch-callout: none;
  color: #fff;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.postCategoryOption div{
  height: 16px;
}
.postCategoryOption.active, 
.postCategoryOption.active:focus, 
.postCategoryOption.active:hover,
.postCategoryOption:focus, 
.postCategoryOption:hover {
  background-color: #CCFF33 !important;
  background-image: none !important;
  color: #2a2a2a !important;
}
.compertitorInfoBrand{
  flex: 1;
  display: flex;
}
.compertitorInfoBrand .logoBrand{
  position: static;
}
.compertitorInfoBrand div{
  flex: 1;
  display: flex;
  padding-left: 6px;
}
.compertitorInfoBrand div p{
  margin-left: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}