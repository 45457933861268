
.socialStrategyCategories{
  width: calc(100% / 3);
  height: 100%;
  position: relative;
}
.socialStrategyCategoriesPrev{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 10px;
  z-index: 1;
}
.socialStrategyCategoriesNext{
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 30px;
  z-index: 1;
}
.socialStrategyCategoriesPrev i,
.socialStrategyCategoriesNext i{
  cursor: pointer;
  color: #ccff33;
  font-size: 24px;
}
.socialStrategyData{
  width: calc(100% / 3);
  height: 100%;
}
.socialStrategyDataContainer{
  width: 100%;
  height: calc(100% - 20px);
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.socialStrategyCategoriesContainer{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 328px;
  margin: 30px 0px;
  background: #363737;
  justify-content: space-between;
  align-items: center;
}
.socialStrategyCategory{
  background: #2b2b2b;
  width: calc(45% - 8px);
  height: calc(45% - 8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 4px;
}
.socialStrategyCategory.active{
  background: #ccff33;
}
.socialStrategyCategory.active p{
  color: #2b2b2b;
}
.socialStrategyCategoryPlaceholder{
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
.socialStrategyCategoryName{
  color: #ccff33;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
.socialStrategyCategoryName p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.socialStrategyCategoryScore{
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  margin: 10px 0px;
  text-align: center;
}
.socialStrategyCategoryPosition{
  color: #ccff33;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.socialStrategyDataItem{
  height: calc((100% / 3) - 8px);
  width: calc(100% - 8px);
  background: #2b2b2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px;
}
.socialStrategyDataItem p{
  font-size: 50px;
  color: #fff;
  font-weight: bold;
  margin: 0px;
  text-align: center;
}
p.socialStrategyDataItemTitle{
  font-size: 14px;
  font-weight: 500;
  color: #ccff33;
}
.socialStrategyPosts{
  width: 400px;
  padding: 10px 0px;
  height: 100%;
  position: relative;
}
.socialStrategyPostsContainer{
  width: 100%;
  position: relative;
}
.socialStrategyPostsContainer .swiper-pagination{
  bottom: 16px;
}
.socialStrategyPosts .swiper-pagination-bullet-active{
  background-color: #ccff33;
}
.socialStrategyPostsPrev{
  text-align: center;
  position: absolute;
  bottom: 14px;
  left: 0px;
  z-index: 1;
  display: inline-block;
  margin-left: 30px;
}
.socialStrategyPostsNext{
  text-align: center;
  position: absolute;
  bottom: 14px;
  right: 0px;
  z-index: 1;
  display: inline-block;
  margin-right: 30px;
}
.socialStrategyPostsPrev i,
.socialStrategyPostsNext i{
  cursor: pointer;
  color: #ccff33;
  font-size: 24px;
}
#socialStrategy .postContainer {
  position: relative;
  width: 368px;
  height: 336px;
  display: inline-block;
  margin: 5px;
  background: #2b2b2b !important;
  padding: 10px;
}
#socialStrategy .postIcon {
  width: 0;
  height: 0;
  border-right: 15px solid #ababab;
  border-top: 15px solid #ababab;
  border-left: 15px solid rgba(0, 0, 0, 0);
  border-bottom: 15px solid rgba(0, 0, 0, 0);
  right: 0;
  top: 0;
  position: absolute !important;
  margin: 0 !important;
}
#socialStrategy .postIcon i {
  color: #2a2a2a;
  position: relative;
  bottom: 15px;
  left: 0px;
  font-size: 14px;
}
#socialStrategy .postImage {
  width: 226px;
  height: 226px;
  vertical-align: top;
  overflow: hidden;
  background: url(../../../../../assets/img/logo.png) no-repeat center center;
  background-color: #2A2A2A;
  background-size: 20px 20px;
  display: flex;
  float: left;
  align-items: center;
}
#socialStrategy .postImage a{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
#socialStrategy .postImage img {
  width: 100%;
}
#socialStrategy .postExtras {
  width: calc(100% - 226px);
  padding: 0;
  display: inline-block;
  height: 226px;
  float: right;
  text-align: center;
}
#socialStrategy .postExtrasType{
  color: #ababab;
  font-size: 14px;
}
#socialStrategy .postExtrasEngagment {
  margin-bottom: 26px;
}
#socialStrategy .postExtrasEngagment h6 {
  font-size: 13px;
  font-weight: bold;
  color: #CF3;
  margin-bottom: 0;
}
#socialStrategy .postExtrasEngagment p {
  font-weight: lighter;
  font-size: 16px;
  color: #BABABA;
  margin-top: 8px;
}
#socialStrategy .postExtrasCategory .dropdownContainer{
  min-width: 100%;
  width: 100px;
}
#socialStrategy .postInsightsInfo {
  display: inline-block;
  float: left;
  width: 50%;
  margin: 5px 0px 0;
}
#socialStrategy .postDate {
  text-align: right;
  font-weight: bold;
  color: #636363;
  margin-bottom: 10px;
  position: relative;
}
#socialStrategy .postDate .logoSmartPost {
  width: 20px;
  position: absolute;
  left: 0;
}
#socialStrategy .postMessage {
  height: 58px;
  font-weight: bold;
  font-size: 14px;
  color: #636363;
  text-align: left;
  margin: 4px 0px;
  padding: 0;
}
#socialStrategy .postTopContainer{
  display: inline-block;
  width: 100%;
}
