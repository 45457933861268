#googleDevices{
	height: calc((476px / 2) - 5px);
}
.googleDevicesChart{
	width: 100%;
	height: 140px;
	display: flex;
	flex-direction: row;
}
.googleDevicesChart div{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: .5px solid #fff;
	overflow: hidden;
}
.googleDevicesChart div p{
	margin-bottom: 0px;
	margin-top: 10px;
}
.googleDevicesChart i{
  font-size: 60px;
  margin-bottom: 4px;
}