#googleGenderSS{
  height: 476px;
}
.googleGenderSSContainer{
  height: calc(100% - 60px);
}
#googleGenderSS table{
  width: 100%;
}
#googleGenderSS table,
#googleGenderSS td,
#googleGenderSS th{  
  border: .5px solid #4a4a4a;
}
#googleGenderSS thead {
  color: #CF3;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googleGenderSS tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
}
#googleGenderSS tr{
  display: flex;
  width: 100%;
}
#googleGenderSS thead tr th,
#googleGenderSS tbody tr td{
  text-align: center;
  flex: 1;
}
#googleGenderSS thead tr th:first-child,
#googleGenderSS tbody tr td:first-child{
  text-align: left;
  flex: 2;
}
#googleGenderSS th,
#googleGenderSS td{
  padding: 4px;
}
.googleGenderSSChartContainer{
  height: 254px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.googleGenderSSChart{
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  margin: 32px 0px;
}
.googleGenderSSfemale,
.googleGenderSSmale{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: .5px solid #fff;
}
.googleGenderSSfemale i,
.googleGenderSSmale i{
  font-size: 60px;
}
.googleGenderSSfemale p,
.googleGenderSSmale p{
  margin-bottom: 0px;
  margin-top: 10px;
}
.googleGenderSSfemale{
  background-color: #ff8bd8;
}
.googleGenderSSmale{
  background-color: #4fabe6;
}