#googleVisitorsSS{
  height: 476px;
}
.googleVisitorsSSContainer{
  height: calc(100% - 60px);
}
#googleVisitorsSS table{
  width: 100%;
}
#googleVisitorsSS table,
#googleVisitorsSS td,
#googleVisitorsSS th{  
  border: .5px solid #4a4a4a;
}
#googleVisitorsSS thead {
  color: #CF3;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
}
#googleVisitorsSS tbody{
  color: #fff;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  display: block;
  overflow: auto;
  max-height: 155px;
}
#googleVisitorsSS tr{
  display: flex;
  width: 100%;
}
#googleVisitorsSS thead tr th,
#googleVisitorsSS tbody tr td{
  text-align: center;
  flex: 1;
}
#googleVisitorsSS thead tr th:first-child,
#googleVisitorsSS tbody tr td:first-child{
  text-align: left;
  flex: 2;
}
#googleVisitorsSS th,
#googleVisitorsSS td{
  padding: 4px;
}