#socialStrategy{
  width: 100%;
  height: 476px;
  overflow: hidden;
}
.socialStrategySwitch{
  display: flex;
}
.socialStrategySwitch label{
  display: block;
  margin-bottom: 0px;
  padding: 4px;
  margin: 0px 4px;
  cursor: pointer;
}
.socialStrategySwitch label input[type=radio]{
  display: none;
}
.socialStrategySwitch label input[type=radio]:checked + p{
  color: #ccff33;
}
.socialStrategyContainer{
  width: 100%;
  height: calc(100% - 68px);
}
.socialStrategyContainer > div{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}