
.adsScoreCampaings{
  width: calc(100% / 3);
  height: 100%;
  position: relative;
}
.adsScoreCampaingsPrev{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 10px;
  z-index: 1;
}
.adsScoreCampaingsNext{
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 30px;
  z-index: 1;
}
.adsScoreCampaingsPrev i,
.adsScoreCampaingsNext i{
  cursor: pointer;
  color: #ccff33;
  font-size: 24px;
}
.adsScoreData{
  width: calc(100% / 3);
  height: 100%;
}
.adsScoreDataContainer{
  width: 100%;
  height: calc(100% - 20px);
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.adsScoreCampaingsContainer{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 328px;
  margin: 30px 0px;
  background: #363737;
  justify-content: space-between;
  align-items: center;
}
.adsScoreCampaing{
  background: #2b2b2b;
  width: calc(45% - 8px);
  height: calc(45% - 8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 4px;
}
.adsScoreCampaing.active{
  background: #ccff33;
}
.adsScoreCampaing.active p{
  color: #2b2b2b;
}
.adsScoreCampaingPlaceholder{
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
.adsScoreCampaingName{
  color: #ccff33;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
.adsScoreCampaingName p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.adsScoreCampaingScore{
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  margin: 10px 0px;
  text-align: center;
}
.adsScoreCampaingPosition{
  color: #ccff33;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.adsScoreDataItem{
  height: calc((100% / 3) - 8px);
  width: calc((100% / 3) - 8px);
  background: #2b2b2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px;
}
.adsScoreDataItem p{
  font-size: 22px;
  color: #fff;
  font-weight: bold;
  margin: 0px;
  text-align: center;
  width: 80%;
}
p.adsScoreDataDate{
  font-size: 12px;
}
p.adsScoreDataSubdate{
  font-size: 16px;
}
p.adsScoreDataCurrency{
  font-size: 16px;
}
p.adsScoreDataObjetive{
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
p.adsScoreDataItemTitle{
  font-size: 10px;
  font-weight: 500;
  color: #ccff33;
}